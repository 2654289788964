import { useEffect, useMemo, useState } from 'react';
import TechnicianSortableAttributes from '@enums/sortable-attributes/technician-sortable-attributes.enum';
import SubcontractorSortableAttributes from '@enums/sortable-attributes/subcontractor-sortable-attributes.enum';
import useGetTechnicians from '@hooks/requests/technicians.get.hook';
import useGetSubcontractors from '@hooks/requests/subcontractors.get.hook';
const useGetAssignees = (params) => {
    const [assignees, setAssignees] = useState();
    const [loading, setLoading] = useState(true);
    const [error] = useState();
    let teamIdsArray;
    if (params.teamId) {
        teamIdsArray = Array.isArray(params.teamId) ? params.teamId : [params.teamId];
    }
    const { techniciansQuery, subcontractorsQuery } = useMemo(() => {
        const tQuery = {
            select: 'all',
            filter: {
                techTeamIds: teamIdsArray
            },
            sort: {
                attribute: TechnicianSortableAttributes.NAME,
                direction: 'asc'
            }
        };
        const sQuery = {
            select: 'all',
            filter: {
                techTeamIds: teamIdsArray
            },
            sort: {
                attribute: SubcontractorSortableAttributes.NAME,
                direction: 'asc'
            }
        };
        return { techniciansQuery: tQuery, subcontractorsQuery: sQuery };
    }, [params.teamId]);
    const { data: techniciansList, loading: techniciansLoading } = useGetTechnicians(techniciansQuery);
    const { data: subcontractorsList, loading: subcontractorsLoading } = useGetSubcontractors(subcontractorsQuery);
    useEffect(() => {
        // Wait until both queries complete to set assignee variable
        if (!techniciansLoading && !subcontractorsLoading) {
            const processedTechs = techniciansList.map((tech) => ({
                type: "technician" /* AssigneeType.TECHNICIAN */,
                key: `${"technician" /* AssigneeType.TECHNICIAN */}-${tech.id}`,
                info: tech
            }));
            const processedSubs = subcontractorsList.map((sub) => ({
                type: "subcontractor" /* AssigneeType.SUBCONTRACTOR */,
                key: `${"subcontractor" /* AssigneeType.SUBCONTRACTOR */}-${sub.id}`,
                info: sub
            }));
            setAssignees([...processedTechs, ...processedSubs]);
        }
    }, [techniciansList, subcontractorsList, techniciansLoading, subcontractorsLoading]);
    useEffect(() => {
        setLoading(techniciansLoading || subcontractorsLoading);
    }, [techniciansLoading, subcontractorsLoading]);
    return { assignees, loading, error };
};
export default useGetAssignees;
