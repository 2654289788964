import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import debounce from 'lodash/debounce';
import { Popup } from '@progress/kendo-react-popup';
import api from '@utils/axios';
import { value } from 'lodash/seq';
import { lookupPath } from '../../routes';
import total from '../Dashboards/InvoiceInsights/nested/Total';

// For use with rails nested_fields_for
function MaterialServicesAutocomplete({ rowName, value, workOrderCorrectionId }) {
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [plDropdownOpen, plSetDropdownOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(value);
  const [page, setPage] = useState(0);
  const activePriceLevel = selectedValue?.priceLevels?.find((x) => x.selected);
  const rowCorrectionId = useRef(workOrderCorrectionId); // Only for edits
  const totalRows = useRef(0);

  const [options, setOptions] = useState([]);

  const anchor = useRef(null);
  const popupRef = useRef(null);
  const priceLevelAnchor = useRef(null);
  const priceLevelPopupRef = useRef(null);

  useEffect(() => {
    const closeDropdownOnScroll = (e) => {
      setDropdownOpen(false);
      plSetDropdownOpen(false);
    };

    const closeDropdownOnBlur = (e) => {
      if (anchor.current?.contains(e.target)) return;
      if (priceLevelAnchor.current?.contains(e.target)) return;
      if (!popupRef.current?.contains(e.target)) setDropdownOpen(false);
      if (!priceLevelPopupRef.current?.contains(e.target)) plSetDropdownOpen(false);
    };

    document.querySelector('.partition__workspace').addEventListener('scroll', closeDropdownOnScroll);
    document.body.addEventListener('click', closeDropdownOnBlur);

    return () => {
      document.querySelector('.partition__workspace').removeEventListener('scroll', closeDropdownOnScroll);
      document.body.removeEventListener('click', closeDropdownOnBlur);
    };
  }, [selectedValue, plDropdownOpen]);

  const handleChange = (event) => {
    const { value } = event.target;
    setInputValue(value);
    debounceLookupMaterialService(value);
  };

  const handleClick = (event) => {
    const { value } = event.target;
    setInputValue(value);

    lookupMaterialService(value);
  };

  const handleSelection = (selectedOption) => {
    setSelectedValue(selectedOption);
    setDropdownOpen(false);
    plSetDropdownOpen(false);

    const proposedCorrectionSelectElement = document.querySelector(
      `select[name*="${rowName}[proposed_correction_id]"]`
    );
    const domOption = document.createElement('option');
    domOption.value = selectedOption.id;
    domOption.text = selectedOption.label;
    for (const [key, value] of Object.entries(selectedOption.jsData)) {
      domOption.setAttribute(`data-${key}`, value);
    }

    proposedCorrectionSelectElement.appendChild(domOption);
    domOption.selected = true;

    const event = new Event('change', { bubbles: true });
    proposedCorrectionSelectElement.dispatchEvent(event);

    const priceLevelSelectElement = document.querySelector(`select[name*="${rowName}[price_level_id]"]`);
    const pl = selectedOption?.priceLevels?.find((x) => x.selected);
    if (pl) {
      const domOption = document.createElement('option');
      domOption.value = pl.id;
      domOption.text = pl.label;
      for (const [key, value] of Object.entries(pl.jsData)) {
        domOption.setAttribute(`data-${key}`, value);
      }

      priceLevelSelectElement.appendChild(domOption);
      domOption.selected = true;
    } else {
      const priceLevelSelectElement = document.querySelector(`select[name*="${rowName}[price_level_id]"]`);
      while (priceLevelSelectElement.firstChild) {
        priceLevelSelectElement.removeChild(priceLevelSelectElement.firstChild);
      }

      const domOption = document.createElement('option');
      domOption.value = '';
      domOption.text = '';

      priceLevelSelectElement.appendChild(domOption);
      domOption.selected = true;
    }
    priceLevelSelectElement.dispatchEvent(event);
  };

  const handleSelectionClear = () => {
    setSelectedValue(null);
    setInputValue('');

    const proposedCorrectionSelectElement = document.querySelector(
      `select[name*="${rowName}[proposed_correction_id]"]`
    );
    while (proposedCorrectionSelectElement.firstChild) {
      proposedCorrectionSelectElement.removeChild(proposedCorrectionSelectElement.firstChild);
    }

    const priceLevelSelectElement = document.querySelector(`select[name*="${rowName}[price_level_id]"]`);
    while (priceLevelSelectElement.firstChild) {
      priceLevelSelectElement.removeChild(priceLevelSelectElement.firstChild);
    }

    const event = new Event('change', { bubbles: true });
    proposedCorrectionSelectElement.dispatchEvent(event);
    priceLevelSelectElement.dispatchEvent(event);
  };

  const debounceLookupMaterialService = useCallback(
    debounce((value) => {
      lookupMaterialService(value);
    }, 800),
    []
  );

  const lookupMaterialService = (value) => {
    setIsLoading(true);
    setPage(0);

    const params = {
      type: 'work_order_material_and_services',
      search_value: value,
      work_order_correction_id: rowCorrectionId.current,
      take: 20,
      skip: 0
    };

    api
      .get(lookupPath(), { params, timeout: 0 })
      .then((response) => {
        totalRows.current = response.data.total_count;
        setOptions(response.data.data);
      })
      .catch((error) => {
        console.log(error);
        totalRows.current = 0;
        setOptions([]);
      })
      .finally(() => {
        setIsLoading(false);
        setDropdownOpen(true);
      });
  };

  return (
    <>
      {selectedValue && (
        <div
          className="qmb-input --x-full"
          style={{ display: 'flex', padding: '8px', boxSizing: 'border-box', alignItems: 'center', width: '24rem' }}>
          <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
            <small>{selectedValue.type}</small>
            <div style={{ display: 'flex', flexWrap: 'wrap', columnGap: '.5rem', alignItems: 'baseline' }}>
              {selectedValue.label}
              {selectedValue?.priceLevels?.length > 0 && (
                <button
                  ref={priceLevelAnchor}
                  type="button"
                  className="qmb-control"
                  style={{ padding: '3px 6px', borderRadius: '10px' }}
                  onClick={() => plSetDropdownOpen(!plDropdownOpen)}>
                  <i className="fa-light fa-diagram-subtask" style={{ fontSize: '1.2rem' }} />
                  <small>{activePriceLevel ? activePriceLevel.label : 'Price Level'}</small>
                </button>
              )}
            </div>
          </div>
          <button className="qmb-control--icon" onClick={handleSelectionClear}>
            <i className="fa-light fa-xmark" />
          </button>
        </div>
      )}
      {!selectedValue && (
        <div className="qmb-input--text --x-full" ref={anchor} data-invalid="true">
          {isLoading && <Spinner />}
          <input value={inputValue} onChange={handleChange} onClick={handleClick} placeholder="Search..." />
        </div>
      )}
      <Popup anchor={priceLevelAnchor.current} show={plDropdownOpen}>
        <PriceLevelsDropdown
          popupRef={priceLevelPopupRef}
          selectedValue={selectedValue}
          handleSelection={handleSelection}
        />
      </Popup>

      <Popup anchor={anchor.current} show={dropdownOpen}>
        <Dropdown
          closeDropdown={() => setDropdownOpen(false)}
          popupRef={popupRef}
          options={options}
          totalRows={totalRows}
          setOptions={setOptions}
          value={inputValue}
          handleSelection={handleSelection}
          rowCorrectionId={rowCorrectionId}
          page={page}
          setPage={setPage}
        />
      </Popup>
      <input type="hidden" />
    </>
  );
}

function Dropdown({
  closeDropdown,
  popupRef,
  options,
  totalRows,
  setOptions,
  value,
  handleSelection,
  rowCorrectionId,
  page,
  setPage
}) {
  function Content() {
    if (options.length > 0) {
      return options.map((o) => <SelectOption data={o} handleSelection={handleSelection} />);
    }
    return (
      <span
        style={{
          padding: '2rem',
          color: '#BBB',
          display: 'flex',
          justifyContent: 'center'
        }}>
        No Match Found
      </span>
    );
  }

  function Pagination() {
    const [isVisible, setIsVisible] = useState(false);
    const targetRef = useRef(null);

    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            setIsVisible(true);
          } else {
            setIsVisible(false);
          }
        },
        {
          root: null,
          rootMargin: '0px',
          threshold: 1.0
        }
      );

      if (targetRef.current) {
        observer.observe(targetRef.current);
      }

      return () => {
        if (targetRef.current) {
          observer.unobserve(targetRef.current);
        }
      };
    }, []);

    useEffect(() => {
      if (isVisible) {
        const params = {
          type: 'work_order_material_and_services',
          search_value: value,
          take: 20,
          skip: (page + 1) * 20,
          work_order_correction_id: rowCorrectionId.current
        };

        api
          .get(lookupPath(), { params, timeout: 0 })
          .then((response) => {
            setOptions((prevData) => [...prevData, ...response.data.data]);
            setPage(page + 1);
          })
          .catch((error) => {
            console.log(error);
            totalRows.current = 0;
            setOptions([]);
          });
      }
    }, [isVisible]);

    if (totalRows.current === options.length) {
      return null;
    }
    return (
      <span ref={targetRef} style={{ display: 'flex', justifyContent: 'center', padding: '2rem' }}>
        <div id="spinner" className="qmb-loading--16" data-title="Loading...">
          <svg role="img">
            <use href="/map.svg#load-spinner" />
          </svg>
        </div>
      </span>
    );
  }

  return (
    <div tabIndex={0} className="qmb-popup" ref={popupRef} style={{ maxWidth: '400px' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          maxHeight: '220px',
          overflowY: 'auto',
          width: '200px'
        }}>
        <Content />
        <Pagination />
      </div>
    </div>
  );
}

function PriceLevelsDropdown({ popupRef, selectedValue, handleSelection }) {
  const handleSelectPriceLevel = (event, selectedId) => {
    event.stopPropagation();
    event.preventDefault();
    handleSelection({
      ...selectedValue,
      priceLevels: selectedValue?.priceLevels?.map((pl) => ({ ...pl, selected: pl.id === selectedId }))
    });
  };

  return (
    <div tabIndex={0} className="qmb-popup" ref={popupRef} style={{ maxWidth: '400px' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          maxHeight: '220px',
          overflowY: 'auto',
          width: '200px'
        }}>
        {selectedValue.priceLevels.map((x) => (
          <button className="qmb-control" onClick={(e) => handleSelectPriceLevel(e, x.id)} disabled={x.selected}>
            {x.label}
          </button>
        ))}

        {selectedValue.priceLevels.some((x) => x.selected) && (
          <button className="qmb-control" onClick={(e) => handleSelectPriceLevel(e, null)}>
            <i className="fa-light fa-xmark" />
            Remove Price Level
          </button>
        )}
      </div>
    </div>
  );
}

function SelectOption({ data, handleSelection }) {
  const handleSelectOption = (event) => {
    event.stopPropagation();
    event.preventDefault();
    handleSelection(data);
  };
  const handleSelectSubOption = (event, subOption) => {
    event.stopPropagation();
    event.preventDefault();
    subOption.selected = true;
    handleSelection(data);
  };

  return (
    <button
      className="qmb-control"
      style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'start', gap: 0 }}
      onClick={(e) => handleSelectOption(e)}>
      <small>{data.type}</small>
      <span>{data.label}</span>
      {data.priceLevels?.map((priceLevel, index) => (
        <SubOption
          priceLevel={priceLevel}
          index={index}
          totalCount={data.priceLevels.length}
          handleClick={handleSelectSubOption}
        />
      ))}
    </button>
  );
}

function SubOption({ priceLevel, index, totalCount, handleClick }) {
  return (
    <button
      className="qmb-control"
      style={{
        marginTop: index === 0 ? '.5rem' : 'auto',
        width: '100%',
        display: 'flex',
        alignItems: 'start',
        gap: '2px',
        padding: '0px',
        color: '#666'
      }}
      onClick={(e) => handleClick(e, priceLevel)}>
      <span style={{ fontFamily: 'monospace' }}>{index === totalCount - 1 ? '└─' : '├─'}</span>
      <small>{priceLevel.label}</small>
    </button>
  );
}

function Spinner() {
  return (
    <div
      id="spinner"
      className="qmb-loading--16"
      style={{
        position: 'absolute',
        right: '5px',
        top: '50%',
        transform: 'translateY(-50%)',
        zIndex: 10
      }}>
      <svg role="img">
        <use href="/map.svg#load-spinner" />
      </svg>
    </div>
  );
}

export default MaterialServicesAutocomplete;
