import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { isTechnician } from '@utils/typeGuards/assignee.typeguard';
import { technicianAvatar, technicianTitle } from '@utils/functions/technician.functions';
import { subcontractorIcon, subcontractorTitle } from '@utils/functions/subcontractor.functions';
import { shuffle } from 'lodash';
import { isVisit } from '@utils/typeGuards/scheduler-event-typeguard';
import { useDispatch, useSelector } from 'react-redux';
import { SwimlaneContext } from './SwimlaneContext';
import { showFleetTrackingModal } from '../../Redux/fleetTrackingSlice';
import TruckSvg from '../Fleet/TruckIcon';
function SwimlaneHeader() {
    const { assignee, events } = useContext(SwimlaneContext);
    const visits = useMemo(() => events.filter((event) => isVisit(event)), [events]);
    /* Fleet Tracking Integration */
    const dispatch = useDispatch();
    //@ts-ignore
    const fleetTrackingActive = useSelector((state) => state.fleet.active);
    //@ts-ignore
    const drivers = useSelector((state) => state.fleet.techs);
    const vehicle = useMemo(() => {
        if (isTechnician(assignee)) {
            let targetVehicle = drivers.find((tech) => tech.id === assignee.info.id);
            if (!targetVehicle && assignee.info.fleetManagerVehicleId) {
                targetVehicle = drivers.find((tech) => tech.vehicle_id === assignee.info.fleetManagerVehicleId);
            }
            return targetVehicle;
        }
        return undefined;
    }, [fleetTrackingActive, drivers, assignee]);
    const handleVehicleClick = () => {
        dispatch(showFleetTrackingModal(vehicle));
    };
    /* End Fleet Tracking Integration */
    const { avatar, name } = useMemo(() => {
        if (isTechnician(assignee)) {
            return { avatar: technicianAvatar(assignee.info), name: technicianTitle(assignee.info) };
        }
        return { avatar: subcontractorIcon(assignee.info), name: subcontractorTitle(assignee.info) };
    }, [assignee]);
    return (React.createElement("div", { role: "presentation", className: "tech__item", style: { overflowX: 'hidden' } },
        React.createElement("div", { className: "tech__card" },
            React.createElement("div", { className: "tech__info" },
                avatar,
                React.createElement("div", { className: "tech__name" }, name)),
            React.createElement("div", { className: "tech__visits" },
                React.createElement("span", { className: "tech__statuses", title: `${visits.length} Visit${visits.length > 1 || visits.length === 0 ? 's' : ''}` },
                    React.createElement(StatusBadges, null),
                    visits.length > 0 && React.createElement("span", { className: "tech__diff" }, visits.length)),
                fleetTrackingActive && vehicle && (React.createElement("button", { type: "button", onClick: handleVehicleClick, className: "qmb-control", style: { border: 'none', zIndex: 10000000 } },
                    React.createElement(TruckSvg, { vehicle: vehicle }))),
                visits.length > 0 && React.createElement(MapExclusionToggle, null)))));
}
function StatusBadges() {
    const { events } = useContext(SwimlaneContext);
    const badges = useMemo(() => shuffle(events.filter((e) => isVisit(e)).map((event) => [event.status.icon, event.status.color])).slice(0, 3), [events.length]);
    return badges.map((badge, i) => (React.createElement("span", { key: i, className: "qmb-status" },
        React.createElement("i", { className: badge[0], style: { color: badge[1] } }))));
}
function MapExclusionToggle() {
    const { showOnMap, events, toggleMapExclusion } = useContext(SwimlaneContext);
    const toggleRef = useRef(null);
    useEffect(() => {
        //@ts-ignore
        $(toggleRef.current).kendoTooltip({
            position: 'right'
        });
    }, []);
    useEffect(() => {
        //@ts-ignore
        const visitText = `${events.length} visit${events.length > 1 || events.length === 0 ? 's' : ''}`;
        //@ts-ignore
        $(toggleRef.current).data('kendoTooltip').options.content = `${!showOnMap ? `Show ${visitText} on` : `Hide ${visitText} from`} map`;
        //@ts-ignore
        $(toggleRef.current).data('kendoTooltip').refresh();
    }, [showOnMap]);
    return (React.createElement("button", { type: "button", className: `qmb-control--icon--toggle${showOnMap ? '--active' : '--dimmer'}`, onClick: toggleMapExclusion, ref: toggleRef, title: `${!showOnMap ? 'Show' : 'Hide'} on Map` },
        React.createElement("i", { className: `fa-light ${!showOnMap ? 'fa-location-dot-slash' : 'fa-location-dot'}` })));
}
export default SwimlaneHeader;
