import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { listingMapLookupPath, listingLookupPath } from 'routes';
import SearchSelect from '@components/Kendo/SearchSelect';
import I18n, { translate } from '@utils/i18n';
import { Dialog } from '@progress/kendo-react-dialogs';

const namespace = 'features.accounts.price_list_selector';

function PriceListSelector({ priceList }) {
  const [priceListId, setPriceListId] = useState(priceList?.id?.toString() ?? '');
  const input = useRef();
  const [open, setOpen] = useState(false);
  const [changeAssociatedBuildings, setChangeAssociatedBuildings] = useState(false);
  const [changeAssociatedItems, setChangeAssociatedItems] = useState(false);

  const onChange = (e) => {
    const newId = e.sender.element[0].value;
    let isChanged;
    if (priceListId === newId) return;
    setPriceListId((old) => {
      isChanged = newId !== old;
      return newId;
    });

    if (isChanged) {
      input.current.dispatchEvent(new Event('change'));

      if (priceList === null) return;

      if (priceList.id.toString() !== newId) setOpen(true);
    }
  };

  const onCloseModal = () => {
    setPriceListId(priceList?.id?.toString());
    setChangeAssociatedBuildings(false);
    setChangeAssociatedItems(false);
    setOpen(false);
  };

  return (
    <>
      <div className="qmb-select" style={{ zIndex: 19001, width: '220px', height: '43px' }}>
        <SearchSelect
          name="product"
          value={priceListId}
          dataUrl={listingLookupPath({ type: 'price_list' })}
          valueMapUrl={listingMapLookupPath({ type: 'price_list' })}
          label={I18n.t('features.accounts.price_list_selector.label')}
          placeholder={I18n.t('generic.select_value')}
          selectOptions={{ id: 'price_list', onChange }}
          itemHeight={50}
        />
      </div>
      {open && (
        <Dialog
          title={translate('dialog_title', { namespace })}
          className="qmb-dialog"
          width={600}
          onClose={onCloseModal}>
          <label className="qmb-checkbox">
            <input
              type="checkbox"
              onChange={() => {
                setChangeAssociatedBuildings((old) => !old);
              }}
              checked={changeAssociatedBuildings}
            />
            {translate('buildings_associated', { namespace })}
          </label>
          <label className="qmb-checkbox">
            <input
              type="checkbox"
              onChange={() => {
                setChangeAssociatedItems((old) => !old);
              }}
              checked={changeAssociatedItems}
            />
            {translate('items_associated', { namespace })}
          </label>
          <div dangerouslySetInnerHTML={{ __html: translate('note', { namespace }) }} />
          <div className="modal__footer pull-right" style={{ justifyContent: 'space-between' }}>
            <button type="button" className="qmb-button" onClick={onCloseModal}>
              {I18n.t('generic.cancel')}
            </button>
            <button
              onClick={() => {
                setOpen(false);
              }}
              type="submit"
              className="qmb-button--submit">
              {I18n.t('generic.confirm')}
            </button>
          </div>
        </Dialog>
      )}
      <input type="hidden" name="account[change_associated_buildings_price_lists]" value={changeAssociatedBuildings} />
      <input type="hidden" name="account[change_associated_items_price_lists]" value={changeAssociatedItems} />
      <input ref={input} type="hidden" name="account[price_list_id]" value={priceListId} />
    </>
  );
}

PriceListSelector.propTypes = {
  priceList: PropTypes.object
};

export default PriceListSelector;
