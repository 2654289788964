import React from 'react';
import { technicianAvatar } from '@utils/functions/technician.functions';
import { subcontractorIcon } from '@utils/functions/subcontractor.functions';
import { isInspectionVisit, isServiceVisit } from '@utils/typeGuards/visit.typeguard';
import { isSubcontractor, isTechnician } from '@utils/typeGuards/assignee.typeguard';
export function visitIcon(visit) {
    return React.createElement("i", { style: { color: visit.status.color }, className: visit.status.icon });
}
export function visitStatusAndTimeLabel(visit) {
    const format = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
    };
    return `${visit.status.label} - ${visit.start.toDate().toLocaleString(undefined, format)}`;
}
export function visitAssigneeIcon(visit) {
    let icon;
    if (isTechnician(visit.assignee))
        icon = technicianAvatar(visit.assignee.info, 16);
    else if (isSubcontractor(visit.assignee))
        icon = subcontractorIcon(visit.assignee.subcontractor, 16);
    else
        icon = React.createElement("i", { className: "fa-light fa-circle-question" });
    return React.createElement("span", { style: { display: 'flex', alignItems: 'center' } }, icon);
}
export function visitAssigneeTitle(visit) {
    if (visit.assignee.technician)
        return visit.assignee.technician.name;
    if (visit.assignee.subcontractor)
        return visit.assignee.subcontractor.name;
    return 'Unassigned';
}
export function buildVisitPatch(visit) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    if (isServiceVisit(visit)) {
        return {
            assignee_type: visit.assignee.type,
            technician_id: visit.assignee.type === "technician" /* AssigneeType.TECHNICIAN */ ? (_b = (_a = visit.assignee.info) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : null : null,
            subcontractor_id: visit.assignee.type === "subcontractor" /* AssigneeType.SUBCONTRACTOR */ ? (_d = (_c = visit.assignee.info) === null || _c === void 0 ? void 0 : _c.id) !== null && _d !== void 0 ? _d : null : null,
            duration: 'durationMins' in visit ? visit.durationMins : visit.end.diff(visit.start, 'minutes'),
            visit_status: visit.status.key,
            scheduled_date: visit.start.toDate(),
            subcontractor_billing_limit: 'billingLimit' in visit ? visit.billingLimit : undefined,
            subcontractor_po_number: 'poNumber' in visit ? visit.poNumber : undefined
        };
    }
    if (isInspectionVisit(visit)) {
        return {
            assignee_type: visit.assignee.type,
            technician_id: visit.assignee.type === "technician" /* AssigneeType.TECHNICIAN */ ? (_f = (_e = visit.assignee.info) === null || _e === void 0 ? void 0 : _e.id) !== null && _f !== void 0 ? _f : null : null,
            subcontractor_id: visit.assignee.type === "subcontractor" /* AssigneeType.SUBCONTRACTOR */ ? (_h = (_g = visit.assignee.info) === null || _g === void 0 ? void 0 : _g.id) !== null && _h !== void 0 ? _h : null : null,
            status_code: visit.status.key,
            duration_mins: 'durationMins' in visit ? visit.durationMins : visit.end.diff(visit.start, 'minutes'),
            scheduled_date: visit.start.toDate(),
            end_date: visit.end.toDate(),
        };
    }
    return {
        assignee_type: visit.assignee.type,
        technician_id: visit.assignee.type === "technician" /* AssigneeType.TECHNICIAN */ ? (_k = (_j = visit.assignee.info) === null || _j === void 0 ? void 0 : _j.id) !== null && _k !== void 0 ? _k : null : null,
        subcontractor_id: visit.assignee.type === "subcontractor" /* AssigneeType.SUBCONTRACTOR */ ? (_m = (_l = visit.assignee.info) === null || _l === void 0 ? void 0 : _l.id) !== null && _m !== void 0 ? _m : null : null,
        status_code: visit.status.key,
        projected_duration_mins: 'durationMins' in visit ? visit.durationMins : visit.end.diff(visit.start, 'minutes'),
        scheduled_date: visit.start.toDate(),
        end_date: visit.end.toDate(),
    };
}
