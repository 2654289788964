import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Table, { TableSize } from '@components/Table/Table';
import { newPriceListPath, priceListPath } from 'routes';
import api from '@utils/axios';
import { translate } from '@utils/i18n';
import ActionButtons from './ActionButtons';

const namespace = 'features.settings.price_lists.index';

function Index({ priceLists }) {
  const [lists, setLists] = useState(priceLists);
  const onSwitch = (priceList) => {
    return () => {
      api.put(priceListPath(priceList.id), { price_list: { active: !priceList.active } }).then(() =>
        setLists((draft) => {
          const index = draft.findIndex((list) => list.id === priceList.id);
          draft[index].active = !draft[index].active;
          return [...draft];
        })
      );
    };
  };

  const onDelete = (priceList) => {
    return () => {
      api
        .delete(priceListPath(priceList.id))
        .then(() => setLists((draft) => draft.filter((list) => priceList.id !== list.id)));
    };
  };

  const columns = [
    {
      label: translate('price_list', { namespace }),
      dataKey: 'name',
      renderer: ({ item }) => <a href={priceListPath(item.id)}>{item.name}</a>
    },
    {
      label: '',
      align: 'left',
      dataKey: 'id',
      width: 70,
      renderer: ({ item }) => <ActionButtons priceList={item} onSwitch={onSwitch(item)} onDelete={onDelete(item)} />
    }
  ];

  return (
    <div className="workspace__body">
      <div className="workspace__major--listing">
        <div className="workspace__header">
          <div className="header__main">
            <h1 className="workspace__title">{translate('title', { namespace })}</h1>
          </div>
        </div>
        <div className="qmb-content-actions">
          <div className="content-actions__group">
            <a className="qmb-control" href={newPriceListPath()}>
              <i className="fa-light fa-circle-plus" />
              {translate('new', { namespace })}
            </a>
          </div>
        </div>
        <div>
          {lists.length === 0 && (
            <div style={{ textAlign: 'center', marginTop: '25px', color: '#1967D2' }}>
              {translate('no_data', { namespace })}
            </div>
          )}
          {lists.length > 0 && <Table columns={columns} data={lists} size={TableSize.Full} variant="listing" />}
        </div>
      </div>
    </div>
  );
}

Index.propTypes = {
  priceLists: PropTypes.array.isRequired
};

export default Index;
