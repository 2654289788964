import { useCallback, useEffect, useRef, useState } from 'react';
import { isTimeOff, isVisit } from '@utils/typeGuards/scheduler-event-typeguard';
const useEventManager = (visits, timeOffs) => {
    const [loading, setLoading] = useState(true);
    const managedEvents = useRef(new Map());
    const [groupedEvents, setGroupedEvents] = useState({});
    useEffect(() => {
        managedEvents.current = new Map();
        visits.forEach((visit) => managedEvents.current.set(visit.key, visit));
        timeOffs.forEach((timeOff) => managedEvents.current.set(timeOff.key, timeOff));
        groupEvents();
    }, [visits, timeOffs]);
    const groupEvents = useCallback(() => {
        var _a, _b;
        const grouped = {};
        if (managedEvents.current) {
            // eslint-disable-next-line no-restricted-syntax
            for (const [, event] of managedEvents.current) {
                let swimlaneKey;
                if (isTimeOff(event)) {
                    swimlaneKey = `${"technician" /* AssigneeType.TECHNICIAN */}-${event.technician.id}`;
                }
                else if (isVisit(event)) {
                    swimlaneKey = `${event.assignee.type}-${(_b = (_a = event.assignee.info) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : 'unassigned'}`;
                }
                else {
                    continue;
                }
                if (!grouped[swimlaneKey]) {
                    grouped[swimlaneKey] = [];
                }
                grouped[swimlaneKey].push(event);
            }
        }
        setGroupedEvents(grouped);
        setLoading(false);
    }, []);
    const moveEvent = useCallback((key, destinationTime) => {
        const targetEvent = managedEvents.current.get(key);
        if (targetEvent && isVisit(targetEvent)) {
            const duration = targetEvent.end.diff(targetEvent.start);
            targetEvent.start = destinationTime;
            targetEvent.end = destinationTime.add(duration, 'millisecond');
            groupEvents(); // TODO: figure out way to reflect changes without regrouping constantly. Inefficient
        }
    }, []);
    const reassignEvent = useCallback((key, destinationAssignee) => {
        const targetEvent = managedEvents.current.get(key);
        if (targetEvent && isVisit(targetEvent)) {
            targetEvent.assignee = destinationAssignee;
            groupEvents();
        }
    }, []);
    const replaceEvent = useCallback((newState) => {
        const targetEvent = managedEvents.current.get(newState.key);
        if (targetEvent && isVisit(targetEvent)) {
            targetEvent.status = newState.status;
            targetEvent.start = newState.start;
            targetEvent.end = newState.end;
            targetEvent.assignee = newState.assignee;
            groupEvents();
        }
    }, []);
    const adjustEventTime = useCallback((key, destinationTime, type) => {
        const targetEvent = managedEvents.current.get(key);
        if (targetEvent && isVisit(targetEvent)) {
            targetEvent.start = type === 'start' ? destinationTime : targetEvent.start;
            targetEvent.end = type === 'end' ? destinationTime : targetEvent.end;
            groupEvents();
        }
    }, []);
    const removeEvent = useCallback((key) => {
        const targetEvent = managedEvents.current.get(key);
        if (targetEvent) {
            managedEvents.current.delete(key);
            groupEvents();
        }
    }, []);
    const addEvent = useCallback((event) => {
        managedEvents.current.set(event.key, event);
        groupEvents();
    }, []);
    return {
        loading,
        groupedEvents,
        actions: { moveEvent, reassignEvent, replaceEvent, adjustEventTime, addEvent, removeEvent }
    };
};
export default useEventManager;
