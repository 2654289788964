import React, { createContext, useContext, useMemo } from 'react';
import useGetTenantDetails from '@hooks/requests/tenant-details.get.hook';
import Error from '../components/Error';
import { TimeZoneContext } from './timezone-context';
const defaultContext = {
    tenant: undefined
};
export const TenantContext = createContext(defaultContext);
export function TenantContextProvider(params) {
    const { data: tenant, loading, error } = useGetTenantDetails();
    const { setTimeZone } = useContext(TimeZoneContext);
    const contextData = useMemo(() => {
        const value = {
            tenant
        };
        return value;
    }, [tenant]);
    const content = useMemo(() => {
        if (error) {
            return React.createElement(Error, { error: error });
        }
        if (tenant) {
            return params.children;
        }
        return null;
    }, [error, tenant, params.children]);
    return React.createElement(TenantContext.Provider, { value: contextData }, content);
}
