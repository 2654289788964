import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { GlobalDragContext } from './GlobalDragContext';
import { replaceVisit } from '../Redux/visitsSlice';
import { ScheduleAndDispatchContext } from './ScheduleAndDispatchContext';
export const VisitPaneContext = createContext(undefined);
export function VisitPaneContextProvider(params) {
    const activeVisit = useRef(undefined);
    const { modifiedEventWatch } = useContext(ScheduleAndDispatchContext);
    const { startDragging, stopDragging } = useContext(GlobalDragContext);
    const dispatch = useDispatch();
    useEffect(() => {
        var _a, _b;
        if (modifiedEventWatch) {
            const transformedResult = {
                key: `${modifiedEventWatch.type}_${modifiedEventWatch.id}`,
                type: modifiedEventWatch.type.toString(),
                id: modifiedEventWatch.id,
                startTime: modifiedEventWatch.start.toISOString(),
                endTime: modifiedEventWatch.end.toISOString(),
                durationMins: modifiedEventWatch.durationMins,
                technicianId: ((_b = (_a = modifiedEventWatch.assignee) === null || _a === void 0 ? void 0 : _a.technician) === null || _b === void 0 ? void 0 : _b.id) || null,
                building: modifiedEventWatch.building.name,
                address: modifiedEventWatch.building.address,
                title: modifiedEventWatch.building.name,
                subtitle: modifiedEventWatch.building.address,
                status: modifiedEventWatch.status.key,
                statusObj: modifiedEventWatch.status,
                statusColor: modifiedEventWatch.status.color,
                statusIcon: modifiedEventWatch.status.icon,
                humanizedStatus: modifiedEventWatch.status.label,
                buildingId: modifiedEventWatch.building.id,
                buildingObj: modifiedEventWatch.building,
                lat: modifiedEventWatch.building.latitude,
                lng: modifiedEventWatch.building.longitude,
                description: null,
                // technician: lastModifiedEvent.technician || null,
                details: modifiedEventWatch.details,
                assignee: modifiedEventWatch.assignee
            };
            dispatch(replaceVisit(transformedResult));
        }
    }, [modifiedEventWatch]);
    const handleComponentExit = useCallback(() => {
        if (activeVisit.current) {
            startDragging(activeVisit.current, () => {
                stopDragging();
            });
        }
    }, []);
    const contextData = useMemo(() => {
        const value = {
            activeVisit,
            handleComponentExit
        };
        return value;
    }, []);
    return React.createElement(VisitPaneContext.Provider, { value: contextData }, params.children);
}
