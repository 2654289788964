import React, { useContext, useMemo } from 'react';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { EventContext } from './EventContext';
import ResizeHandles from './ResizeHandles';
function EventVisit() {
    const { event } = useContext(EventContext);
    const { clusterInfo, state, metadata, handleMouseDown } = useContext(EventContext);
    const dynamicStyles = useMemo(() => {
        let appendColor;
        switch (event.status.label) {
            case 'Scheduled':
                appendColor = '1f';
                break;
            case 'Started':
                appendColor = '3d';
                break;
            default:
                appendColor = '26';
        }
        return {
            left: `${metadata.startTimeAsPercentage}%`,
            width: `${metadata.endTimeAsPercentage - metadata.startTimeAsPercentage}%`,
            height: `${(100 / clusterInfo.subdivision) * clusterInfo.span}%`,
            top: `calc((${100 / clusterInfo.subdivision}% ) * ${clusterInfo.offset})`,
            borderTop: clusterInfo.offset === 0 ? 'unset' : 'transparent',
            '--custom-status-color': `${event.status.color}${appendColor}`
        };
    }, [event, clusterInfo, metadata]);
    const dynamicClass = useMemo(() => {
        const classes = ['event__item--custom'];
        if (state === 1 /* EventState.REPOSITIONING */)
            classes.push('--dragging');
        return classes.join('');
    }, [state]);
    return (React.createElement("div", { className: dynamicClass, style: dynamicStyles, onMouseDown: (e) => handleMouseDown(e.nativeEvent) },
        React.createElement("div", { className: "event__card" },
            React.createElement(ResizeHandles, null),
            React.createElement(Tooltip, { anchorElement: "target", position: "top" },
                React.createElement("div", { className: "event__crux" },
                    React.createElement("span", { className: "event__type" },
                        React.createElement(Icon, { type: event.type })),
                    React.createElement("span", { className: "event__time" }, event.start.format('hh:mm a')),
                    React.createElement("span", { className: "event__status qmb-status" },
                        React.createElement("i", { title: event.status.label, className: event.status.icon, style: { color: event.status.color } })),
                    React.createElement("span", { className: "event__title" }, event.title))),
            React.createElement("div", { className: "event__context" }, event.subtitle))));
}
function Icon({ type }) {
    switch (type) {
        case "Visit" /* VisitType.SERVICE_VISIT */:
            return React.createElement("i", { className: "fa-light fa-screwdriver-wrench" });
        case "InspectionVisit" /* VisitType.INSPECTION_VISIT */:
        case "Inspection" /* VisitType.INSPECTION_PRIMARY_VISIT */:
            return React.createElement("i", { className: "fa-light fa-clipboard-check" });
        // case 'TimeOff':
        //   return <i className="fa-light fa-calendar-clock" />;
        default:
            return React.createElement("i", { className: "fa-light fa-square-question" });
    }
}
export default EventVisit;
