import { useMemo, useContext } from 'react';
import { CalendarContext } from '../CalendarContext';

export default function useEvents() {
  const {
    inspectionStatusesForSelect,
    visitStatusesForSelect,
    state: { events, statuses, technicians, selectedTechnicianIds, selectedSubcontractorIds }
  } = useContext(CalendarContext);

  const selectedStatusIds = useMemo(() => Object.keys(statuses).filter((key) => statuses[key]), [statuses]);

  const statusesForSelect = (type) => {
    if (type === 'Visit') return visitStatusesForSelect;

    return inspectionStatusesForSelect;
  };

  const findStatus = (event) => statusesForSelect(event.type).find((status) => status.value === event.status);

  const findTech = (event) => technicians.find((tech) => event.technicianId == tech.id);

  const findColor = (event, status) => {
    if (event.type === 'TimeOff') return event.color;

    if (selectedStatusIds.includes(event.groupStatus)) return `${status.color}31`;

    if (event.assignee.technician !== undefined) {
      if (event.assignee.technician === null) return '#fff';
      return event.assignee.technician.color;
    }

    if (event.assignee.subcontractor !== undefined) {
      if (event.assignee.subcontractor === null) return '#fff';
      return event.assignee.subcontractor.color;
    }

    return '#fff';
  };

  const filteredEvents = useMemo(() => {
    return events.map((event) => {
      const statusObject = findStatus(event);
      const technician = findTech(event);
      const color = findColor(event, statusObject);

      return {
        ...event,
        backgroundColor: color,
        borderColor: color,
        editable: event.type !== 'TimeOff',
        technician,
        statusObject
      };
    });
  }, [statuses, events, selectedTechnicianIds, selectedSubcontractorIds]);

  return filteredEvents;
}
