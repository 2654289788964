import React, { useCallback, useContext, useMemo, useRef } from 'react';
import { EventContext } from './EventContext';
function ResizeHandles() {
    const { event, metadata, handleResizeMouseDown } = useContext(EventContext);
    const left = useRef(null);
    const right = useRef(null);
    const isSnapping = useRef(false);
    const ActivateHandle = (ref) => {
        // ref.current.classList.add('--active');
    };
    const DragHandle = (e, ref, isLeftHandle) => {
        e.stopPropagation();
    };
    const ActivateSnapping = (ref) => {
        ref.current.classList.add('--snapping');
        isSnapping.current = true;
    };
    const DeactivateSnapping = (ref) => {
        ref.current.classList.remove('--snapping');
        isSnapping.current = false;
    };
    const HandleResizerMouseDown = useCallback((e, isLeft) => {
        e.stopPropagation();
        handleResizeMouseDown(isLeft ? 'start' : 'end', isSnapping.current);
    }, []);
    const leftHandle = useMemo(() => {
        return (React.createElement("div", { ref: left, className: "event__handle--left", onMouseDown: (e) => HandleResizerMouseDown(e, true) },
            React.createElement("div", { className: "snap-grab-zone", onMouseEnter: () => ActivateSnapping(left), onMouseLeave: () => DeactivateSnapping(left) })));
    }, []);
    const rightHandle = useMemo(() => {
        return (React.createElement("div", { ref: right, className: "event__handle--right", onMouseDown: (e) => HandleResizerMouseDown(e, false) },
            React.createElement("div", { className: "snap-grab-zone", onMouseEnter: () => ActivateSnapping(right), onMouseLeave: () => DeactivateSnapping(right) })));
    }, []);
    return (React.createElement(React.Fragment, null,
        leftHandle,
        rightHandle));
}
export default ResizeHandles;
