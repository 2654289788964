import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { translate } from '@utils/i18n';
import { Popup } from '@progress/kendo-react-popup';
import { toggleMap } from './Redux/visitsSlice';
import { toggleScheduler, toggleVisitStatusScheduled } from './Redux/schedulerSlice';

import {
  companyHolidaysPath,
  manageWorkflowStatusPath,
  allSettingsPath,
  techniciansPath,
  technicianDayOffsPath
} from '../../routes';
import { showFleetDropdown } from "./Redux/fleetTrackingSlice";
import FleetDropdown from "./FleetDropdown";
import { TenantContext } from '../../contexts/tenant-context';
import { ScheduleAndDispatchContext } from './Contexts/ScheduleAndDispatchContext';

const namespace = 'features.map_scheduler.settings';

function SettingsPopover({permissions}) {
  const anchor = useRef(null);
  const popup = useRef(null);
  const [open, setOpen] = useState(false);
  const mapActive = useSelector((state) => state.map.mapActive);
  const boardActive = useSelector((state) => state.scheduler.schedulerActive);
  const visitStatusScheduledActive = useSelector((state) => state.scheduler.visitStatusScheduledActive);
  const dispatch = useDispatch();
  const fleetTrackingActive = useSelector((state) => state.fleet.active);
  const azugaActive = useSelector((state) => state.fleet.azuga);

  const handleBodyClick = (event) => {
    if (popup.current?._popup?.contains(event.target)) return;
    if (anchor.current.contains(event.target)) return;

    setOpen(false);
  };

  useEffect(() => {
    document.body.addEventListener('click', handleBodyClick);
    return () => {
      document.body.removeEventListener('click', handleBodyClick);
    };
  }, []);

  const onTogglePopup = () => {
    setOpen(!open);
  };

  const onChangeMap = () => {
    dispatch(toggleMap());
  };

  const onChangeBoard = () => {
    dispatch(toggleScheduler());
  };

  const onChangeStatusScheduled = () => {
    dispatch(toggleVisitStatusScheduled());
  };
  
  return (
    <>
      <div className="filters__group--aside">
        {fleetTrackingActive && <FleetDropdown />}
        {fleetTrackingActive && azugaActive && (
          <>
            <a href="/map/fleet_manager_sso" className="qmb-control--reset" target="_blank">
              <span>
                Inspect Point Fleet&nbsp;
                <i className="fa-light fa-arrow-up-right-from-square" />
              </span>
            </a>
            &nbsp;&nbsp;
          </>
        )}
        <button type="button" className="qmb-control--icon" title="Settings" ref={anchor} onClick={onTogglePopup}>
          <i className="fa-light fa-gear" />
        </button>
      </div>
      <Popup anchor={anchor.current} show={open} ref={popup}>
        <div className="qmb-popup--actions">
          <div className="popup__section--group">
            <h4 className="popup__title--group">{translate('title', { namespace })}:</h4>
            <ul role="presentation">
              <li title={translate('toggle_map', { namespace })}>
                <label className="qmb-checkbox">
                  <input onChange={onChangeMap} type="checkbox" checked={mapActive} />
                  <i className="fa-light fa-map-location-dot" />
                  {translate('map', { namespace })}
                </label>
              </li>

              <li title={translate('toggle_board', { namespace })}>
                <label className="qmb-checkbox">
                  <input onChange={onChangeBoard} type="checkbox" checked={boardActive} />
                  <i className="fa-light fa-bars-progress" />
                  {translate('board', { namespace })}
                </label>
              </li>
            </ul>
          </div>
          <hr className="popup__split" />
          <div className="popup__section--group">
            <h4 className="popup__title--group">{translate('visits', { namespace })}:</h4>
            <ul role="presentation">
              <li>
                <span
                  className="qmb-toggle-switch--check"
                  title={translate('toggle_visit_status_scheduled', { namespace })}>
                  <input
                    id="toggle_visit_status_scheduled"
                    type="checkbox"
                    checked={visitStatusScheduledActive}
                    onChange={onChangeStatusScheduled}
                    readOnly
                  />

                  <label htmlFor="toggle_visit_status_scheduled" className="switch__visual" />
                  <label htmlFor="toggle_visit_status_scheduled" className="qmb-label">
                    {translate('set_visit_status_scheduled', { namespace })}
                  </label>
                </span>
              </li>
            </ul>
          </div>
          {(permissions.time_off || permissions.statuses || permissions.techs) && (
            <>
              <hr className="popup__split" />
              <div className="popup__section--group">
                {permissions.time_off && (
                  <a href={companyHolidaysPath()} className="qmb-control">
                    {translate('company_holidays', { namespace })}
                  </a>
                )}
                {permissions.statuses && (
                  <a href={manageWorkflowStatusPath()} className="qmb-control">
                    {translate('inspection_statuses', { namespace })}
                  </a>
                )}
                {permissions.techs && (
                  <a href={techniciansPath()} className="qmb-control">
                    {translate('manage_technicians', { namespace })}
                  </a>
                )}
                {permissions.time_off && (
                  <a href={technicianDayOffsPath()} className="qmb-control">
                    {translate('manage_time_off', { namespace })}
                  </a>
                )}
              </div>
            </>
          )}
          {permissions.settings && (
            <>
              <hr className="popup__split" />
              <div className="popup__section--group">
                <a href={allSettingsPath()} className="qmb-control">
                  {translate('settings', { namespace })}
                </a>
              </div>
            </>
          )}
        </div>
      </Popup>
    </>
  );
}

export default SettingsPopover;
