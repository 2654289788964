import React, { useState, useRef, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Popup } from '@progress/kendo-react-popup';
import { translate } from '@utils/i18n';

const namespace = 'features.settings.price_lists.index';

function ActionButtons({ priceList, onSwitch, onDelete }) {
  const [open, setOpen] = useState(false);
  const anchor = useRef(null);
  const popup = useRef(null);

  const handleBodyClick = useCallback((event) => {
    if (popup.current?._popup?.contains(event.target)) return;
    if (anchor.current.contains(event.target)) return;

    setOpen(false);
  }, []);

  useEffect(() => {
    document.body.addEventListener('click', handleBodyClick);
    return () => {
      document.body.removeEventListener('click', handleBodyClick);
    };
  }, []);

  const onAddEventPopup = useCallback(() => {
    setOpen(!open);
  }, []);

  return (
    <>
      <span>
        {priceList.active && (
          <button type="button" className="qmb-control--icon" onClick={onSwitch}>
            <i className="fa-light fa-eye" />
          </button>
        )}
        {!priceList.active && (
          <button type="button" className="qmb-control--icon" onClick={onSwitch}>
            <i className="fa-light fa-eye-slash" />
          </button>
        )}
        <button type="button" className="qmb-control--icon" ref={anchor} onClick={onAddEventPopup}>
          <i className="fa-regular fa-ellipsis" />
        </button>
      </span>
      <Popup anchor={anchor.current} show={open} ref={popup}>
        <div className="qmb-popup--action-list">
          <ul role="presentation">
            <li key="duplicate">
              <button type="button" disabled>
                <i className="fa-light fa-copy" />
                {translate('duplicate', { namespace })}
              </button>
            </li>
            {/*<li key="delete">
              <button type="button" onClick={onDelete} className="red">
                <i className="fa-light fa-trash-can" />
                {translate('delete', { namespace })}
              </button>
            </li>*/}
          </ul>
        </div>
      </Popup>
    </>
  );
}

ActionButtons.propTypes = {
  priceList: PropTypes.array.isRequired,
  onSwitch: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default ActionButtons;
