import { DateTime } from 'luxon';
import { blackOrWhiteForeground } from '../../utils/colors';

export function hexToHue(hexColor) {
  // Remove the '#' if it exists at the beginning of the hex color code
  const hexColorFormatted = hexColor.replace(/^#/, '');

  // Parse the hex values for red, green, and blue
  const r = parseInt(hexColorFormatted.substring(0, 2), 16);
  const g = parseInt(hexColorFormatted.substring(2, 4), 16);
  const b = parseInt(hexColorFormatted.substring(4, 6), 16);

  // Convert the RGB values to the HSL color model
  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  let hue;

  if (max === min) {
    hue = 0; // Achromatic (gray)
  } else {
    const delta = max - min;
    if (max === r) {
      hue = ((g - b) / delta) % 6;
    } else if (max === g) {
      hue = (b - r) / delta + 2;
    } else {
      hue = (r - g) / delta + 4;
    }
    hue *= 60; // Convert to degrees
  }

  if (hue < 0) {
    hue += 360; // Make sure hue is non-negative
  }

  return Math.round(hue); // Round to the nearest whole number
}

export function pinContent(visit) {
  if (visit.assignee.type === 'subcontractor') {
    const subIcon = document.createElement('div');
    subIcon.innerHTML = '<i class="fa-light fa-handshake"></i>';

    if (visit.assignee.subcontractor == null) {
      return {
        glyph: subIcon,
        background: '#262626',
        borderColor: '#919191',
        glyphColor: '#FFF'
      };
    }
    return {
      glyph: subIcon,
      background: visit.assignee.subcontractor.color,
      borderColor: visit.assignee.subcontractor.color,
      glyphColor: blackOrWhiteForeground(visit.assignee.subcontractor.color)
    };
  }

  if (visit.assignee.type === 'technician' && visit.assignee.info ) {
    const content = {
      background: visit.assignee.info.color,
      borderColor: `hsl(${hexToHue(visit.assignee.info.color)} 70% 70%)`
    };

    if (visit.assignee.info.photoUrl) {
      const glyphImg = document.createElement('img');
      glyphImg.style.width = '140%';
      glyphImg.style.borderRadius = '50%';
      glyphImg.src = visit.assignee.info.photoUrl;
      content.glyph = glyphImg;
    } else {
      const glyphSvg = document.createElement('svg');
      const glyphSvgText = document.createElement('text');
      const glyphSvgShape = document.createElement('path');
      glyphSvgText.setAttribute('x', '50%');
      glyphSvgText.setAttribute('y', '55%');
      glyphSvgText.setAttribute('dominant-baseline', 'middle');
      glyphSvgText.setAttribute('text-anchor', 'middle');
      glyphSvgText.innerHTML = visit.assignee.info.name[0];
      glyphSvgText.style.color = '#FFF';
      glyphSvgShape.setAttribute(
        'd',
        'M256,0C114.62,0,0,114.62,0,256c0,256.15,256,384,256,384s256-127.85,256-384C512,114.62,397.38,0,256,0z'
      );
      glyphSvgShape.setAttribute('fill', content.background);
      glyphSvg.setAttribute('viewBox', '0 0 16 16');
      glyphSvg.appendChild(glyphSvgText);

      const glyphContent = document.createElement('span');
      glyphContent.className = 'qmb-avatar';
      glyphContent.appendChild(glyphSvgText);
      content.glyph = glyphContent;
    }
    return content;
  }

  const icon = document.createElement('div');
  icon.innerHTML = '<i class="fa-light fa-user-helmet-safety"></i>';
  return {
    glyph: icon,
    background: '#262626',
    borderColor: '#919191',
    glyphColor: '#FFF'
  };
}

export const getTimeFromMouseXPosition = (mouseEvent, laneId, activeDay, timeZone) => {
  const activeLane = document.getElementById(laneId);
  const clickX = mouseEvent.clientX - activeLane.getBoundingClientRect().left;
  const percentage = (clickX / activeLane.clientWidth) * 100;
  const minutes = (percentage / 100) * 1440;
  const hours = Math.floor(minutes / 60);
  const minutesRemainder = Math.floor(minutes % 60);
  const targetTime = DateTime.fromISO(activeDay, { zone: timeZone }).set({ hour: hours, minute: minutesRemainder });
  return targetTime.toString();
};
