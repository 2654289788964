import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import I18n from '@utils/i18n';

function Pagination({ totalCount, setPage, options }) {
  const targetRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setPage((old) => old + 1);
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 1.0
      }
    );

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, []);

  if (totalCount === options.length) {
    return null;
  }

  return (
    <span ref={targetRef} style={{ display: 'flex', justifyContent: 'center', padding: '2rem' }}>
      <div id="spinner" className="qmb-loading--16" data-title={`${I18n.t('generic.loading')}...`}>
        <svg role="img">
          <use href="/map.svg#load-spinner" />
        </svg>
      </div>
    </span>
  );
}

Pagination.propTypes = {
  totalCount: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired
};

export default Pagination;
