import { useCallback, useContext } from 'react';
import { dateClient } from '@utils/date-client';
import { TimeZoneContext } from '../../contexts/timezone-context';
const useHolidayProcessor = () => {
    const { timezone } = useContext(TimeZoneContext);
    const processHoliday = useCallback((holiday) => {
        const processedHoliday = Object.assign(Object.assign({}, holiday), { start: dateClient(holiday.start).tz(timezone), end: dateClient(holiday.end).tz(timezone) });
        return processedHoliday;
    }, []);
    return { processHoliday };
};
export default useHolidayProcessor;
