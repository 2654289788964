export const loadCacheFromLocalStorage = (cacheKey) => {
  if (!localStorage.getItem(cacheKey)) return null;

  try {
    const cacheItem = JSON.parse(localStorage.getItem(cacheKey));
    return cacheItem.data;
  } catch {
    console.log(`Issue with Kendo grid cache: ${cacheKey}`);
    localStorage.removeItem(cacheKey);
    return null;
  }
};

export const saveCacheToLocalStorage = (cacheKey, data) => {
  const cacheItem = {
    data
  };

  localStorage.setItem(cacheKey, JSON.stringify(cacheItem));
};

export const toRestoreGridPage = () => {
  return String(window.performance.getEntriesByType('navigation')[0].type) === 'back_forward';
};
