import api from 'axios';
import applyCaseMiddleware from 'axios-case-converter';
import qs from 'qs';
// On API Calls:
// - Convert camelCase to snake_case for typescript -> rails standards
// - Convert arrays to compatible rails array syntax
/* eslint-disable */
const apiClient = applyCaseMiddleware(api.create({
    paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'brackets' });
    },
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
    }
}));
export default apiClient;
