import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Window } from '@progress/kendo-react-dialogs';
import { apiInternalTimeOffPath, editTechnicianDayOffPath } from '../../../routes';
import { Loader, LoaderSizes } from '../../../components/Loader/Loader';
import api from 'axios';
import { translate } from '@utils/i18n';
import TimeOffDetails from './nested/details';
import dayjs from 'dayjs';
function TimeOffModal(params) {
    const namespace = 'features.modals.time_off_modal';
    const [timeOff, setTimeOff] = useState();
    const [userSelectionLocked, setUserSelectionLocked] = useState(false);
    const setUserSelectionLock = useCallback((preventSelections) => {
        if (userSelectionLocked && !preventSelections)
            setUserSelectionLocked(false);
        if (!userSelectionLocked && preventSelections)
            setUserSelectionLocked(true);
    }, [userSelectionLocked]);
    const ModalContent = useMemo(() => {
        if (timeOff)
            return (React.createElement(React.Fragment, null,
                React.createElement(TimeOffDetails, { timeOff: timeOff })));
        else
            return (React.createElement("div", { style: { height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' } },
                React.createElement(Loader, { size: LoaderSizes.large })));
    }, [timeOff]);
    useEffect(() => {
        document.querySelector('.k-overlay').addEventListener('click', params.onClose);
        const url = apiInternalTimeOffPath(params.id);
        api
            .get(url)
            .then((response) => {
            const result = response.data.time_off;
            if (result) {
                const tmpTimeOff = Object.assign(Object.assign({}, result), { start: dayjs(result.start), end: dayjs(result.end) });
                setTimeOff(tmpTimeOff);
            }
        })
            .catch((error) => {
            console.error(error);
            setTimeOff(undefined);
        });
    }, []);
    const Title = ({ timeOff }) => {
        const style = { width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' };
        const { icon, link } = useMemo(() => {
            if (!timeOff) {
                return {
                    icon: React.createElement(Loader, { size: LoaderSizes.small }),
                    link: null
                };
            }
            return {
                icon: React.createElement("i", { className: "fa-light fa-umbrella-beach" }),
                link: (React.createElement(React.Fragment, null,
                    React.createElement("a", { href: editTechnicianDayOffPath(timeOff.id), target: "_blank", className: "qmb-control--sm", rel: "noreferrer" },
                        translate('details_link', { namespace }),
                        React.createElement("i", { className: "fa-light fa-arrow-up-right-from-square" }))))
            };
        }, [timeOff]);
        return (React.createElement("div", { style: style },
            React.createElement("span", { className: "dialog__faux-title" },
                icon,
                (timeOff === null || timeOff === void 0 ? void 0 : timeOff.title) || translate('time_off', { namespace })),
            link));
    };
    return (React.createElement(Window, { modal: true, onClose: params.onClose, title: React.createElement(Title, { timeOff: timeOff }), minWidth: 240, initialWidth: 480, initialHeight: 300, minHeight: 300, minimizeButton: () => null, maximizeButton: () => null, restoreButton: () => null, className: "qmb-dialog", style: { userSelect: userSelectionLocked ? 'none' : 'auto' }, onResize: (e) => setUserSelectionLock(!e.end) }, ModalContent));
}
export default TimeOffModal;
