import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Table, { TableSize } from '@components/Table/Table';
import { taxItemPath, newTaxItemPath, editTaxGroupPath, editTaxRatePath } from 'routes';
import api from '@utils/axios';
import { translate } from '@utils/i18n';
import ActionButtons from './ActionButtons';
import ExpandableRow from './ExpandableRow';

const namespace = 'features.settings.tax_items.index';

function Index({ taxItems }) {
  const [items, setItems] = useState(taxItems);
  const [expandedGroups, setExpandedGroups] = useState({});
  const qbEnabled = taxItems.filter((item) => item.external_id !== null && item.active).length > 0;

  const onSwitch = (taxItem) => {
    return () => {
      api.put(taxItemPath(taxItem.id), { tax_item: { active: !taxItem.active } }).then(() =>
        setItems((draft) => {
          return draft.map((item) => {
            if (item.all_tax_rates) {
              item.rate = calculateGroupRate(item);
              item.all_tax_rates = item.all_tax_rates.map((rate) => {
                if (rate.id === taxItem.id) {
                  return { ...rate, active: !rate.active };
                }
                return rate;
              });
            }
            if (item.id === taxItem.id) {
              return { ...item, active: !item.active };
            }
            return item;
          });
        })
      );
    };
  };

  const onDuplicate = (taxItem) => {
    return () => {
      api
        .post(`${taxItemPath(taxItem.id)}/duplicate`)
        .then((response) => {
          setItems(response.data.tax_items);
        })
        .catch((error) => {
          alert(translate('failed_duplicate', { namespace }));
        });
    };
  };

  const toggleExpandGroup = (groupId, event) => {
    if (
      (event.target.tagName === 'I' && !event.target.hasAttribute('data-allow-click')) ||
      event.target.tagName === 'A' ||
      event.target.tagName === 'BUTTON'
    ) {
      return;
    }

    setExpandedGroups((prev) => ({
      ...prev,
      [groupId]: !prev[groupId]
    }));
  };

  const calculateGroupRate = (group) => {
    const num = group.all_tax_rates
      .filter((rate) => rate.active)
      .reduce((total, rate) => total + parseFloat(rate.rate), 0);
    return Number.isInteger(num) ? num.toFixed(1) : parseFloat(num.toFixed(5)).toString();
  };

  const columns = useMemo(
    () => [
      {
        label: translate('name', { namespace }),
        dataKey: 'name',
        renderer: ({ item }) => (
          <>
            {item.external_id !== null && <span>{item.name}</span>}
            {item.external_id === null && (
              <a href={item.type === 'TaxGroup' ? editTaxGroupPath(item.id) : editTaxRatePath(item.id)}>{item.name}</a>
            )}
            {item.type === 'TaxGroup' && (
              <i
                className={`fa fa-light pl-2 ${expandedGroups[item.id] ? 'fa-angle-up' : 'fa-angle-down'}`}
                style={{ cursor: 'pointer' }}
                data-allow-click
              />
            )}
          </>
        )
      },
      {
        label: translate('agency', { namespace }),
        dataKey: 'agency',
        renderer: ({ item }) => item.tax_agency
      },
      {
        label: translate('rate', { namespace }),
        dataKey: 'rate',
        renderer: ({ item }) => `${item.type === 'TaxGroup' ? calculateGroupRate(item) : item.rate}%`
      },
      {
        label: '',
        align: 'left',
        dataKey: 'id',
        width: 70,
        renderer: ({ item }) => (
          <ActionButtons
            taxItem={item}
            onSwitch={onSwitch(item)}
            onDuplicate={onDuplicate(item)}
            buttonDuplicated={false}
            qbEnabled={qbEnabled}
          />
        )
      }
    ],
    [items, expandedGroups]
  );

  return (
    <div className="workspace__body">
      <div className="workspace__major--listing">
        <div className="workspace__header">
          <div className="header__main">
            <h1 className="workspace__title">{translate('title', { namespace })}</h1>
          </div>
        </div>
        {!qbEnabled && (
          <div className="qmb-content-actions">
            <div className="content-actions__group">
              <a className="qmb-control" href={newTaxItemPath()}>
                <i className="fa fa-light fa-circle-plus" />
                {translate('new', { namespace })}
              </a>
            </div>
          </div>
        )}
        <div>
          {items.length === 0 && (
            <div style={{ textAlign: 'center', marginTop: '25px', color: '#1967D2' }}>
              {translate('no_data', { namespace })}
            </div>
          )}
          {items.length > 0 && (
            <Table
              columns={columns}
              data={items}
              size={TableSize.Full}
              variant="listing"
              expandableRowRenderer={({ item }) =>
                expandedGroups[item.id] ? (
                  <ExpandableRow item={item} onSwitch={onSwitch} onDuplicate={onDuplicate} qbEnabled={qbEnabled} />
                ) : null
              }
              onRowClick={({ item }, event) => {
                if (item.type === 'TaxGroup') toggleExpandGroup(item.id, event);
              }}
              expandedRows={expandedGroups}
              hover
            />
          )}
        </div>
      </div>
    </div>
  );
}

Index.propTypes = {
  taxItems: PropTypes.array.isRequired
};

export default Index;
