import { useCallback, useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { dateClient } from '@utils/date-client';
import { TimeZoneContext } from '../contexts/timezone-context';
const useDateManager = (initialDate) => {
    const { timezone } = useContext(TimeZoneContext);
    const [activeDate, setActiveDate] = useState(dateClient().tz(timezone).startOf('d'));
    const incrementDate = useCallback(() => {
        setActiveDate((prevDate) => prevDate.add(1, 'd'));
    }, []);
    const decrementDate = useCallback(() => {
        setActiveDate((prevDate) => prevDate.subtract(1, 'd'));
    }, []);
    const setDate = useCallback((date) => {
        setActiveDate(dayjs(date).utc().tz(timezone).startOf('day'));
    }, [timezone]);
    useEffect(() => {
        setActiveDate((prevDate) => prevDate.tz(timezone, true).startOf('day'));
    }, [timezone]);
    return { activeDate, incrementDate, decrementDate, setDate };
};
export default useDateManager;
