import { useEffect, useState } from 'react';
import useHolidayProcessor from '@hooks/processors/use-holiday-processor-hook';
import apiClient from '@utils/api-client';
import { apiInternalHolidaysPath } from '../../../routes';
const useGetHolidays = (queryParams) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const { processHoliday } = useHolidayProcessor();
    useEffect(() => {
        const controller = new AbortController();
        setLoading(true);
        apiClient
            .get(apiInternalHolidaysPath(), {
            params: queryParams,
            signal: controller.signal
        })
            .then((response) => {
            const processedData = response.data.data.map((h) => processHoliday(h));
            setData(processedData);
        })
            .catch((err) => {
            if (!controller.signal.aborted) {
                setData([]);
                setError(err);
            }
        })
            .finally(() => {
            if (!controller.signal.aborted) {
                setLoading(false);
            }
        });
        return () => {
            controller.abort();
        };
    }, [queryParams]);
    return { data, loading, error };
};
export default useGetHolidays;
