import { useCallback, useEffect, useRef, useState } from 'react';
import useVisitPatch from '@hooks/requests/visit-patch-hook';
// Manually manage the state of events and swimlanes in the scheduler to reduce async overhead
const useEventManagerOld = (events) => {
    const [loading, setLoading] = useState(true);
    const managedEvents = useRef(new Map());
    const [groupedEvents, setGroupedEvents] = useState({});
    const { save: saveVisit, saving, result: savedVisit } = useVisitPatch();
    const [lastModifiedEvent, setLastModifiedEvent] = useState();
    useEffect(() => {
        managedEvents.current = new Map(events.map((event) => [event.key, event]));
        groupEvents();
    }, [events]);
    const groupEvents = useCallback(() => {
        var _a, _b;
        const grouped = {};
        if (managedEvents.current) {
            // eslint-disable-next-line no-restricted-syntax
            for (const [, event] of managedEvents.current) {
                const swimlaneKey = `${event.assignee.type}-${(_b = (_a = event.assignee.info) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : 'unassigned'}`;
                if (!grouped[swimlaneKey]) {
                    grouped[swimlaneKey] = [];
                }
                grouped[swimlaneKey].push(event);
            }
        }
        setGroupedEvents(grouped);
        setLoading(false);
    }, []);
    const moveEvent = useCallback((key, destinationTime) => {
        const targetEvent = managedEvents.current.get(key);
        if (targetEvent) {
            const duration = targetEvent.end.diff(targetEvent.start);
            targetEvent.start = destinationTime;
            targetEvent.end = destinationTime.add(duration, 'millisecond');
            groupEvents(); // TODO: figure out way to reflect changes without regrouping constantly. Inefficient
        }
    }, []);
    const reassignEvent = useCallback((key, destinationAssignee) => {
        const targetEvent = managedEvents.current.get(key);
        if (targetEvent) {
            targetEvent.assignee = destinationAssignee;
            groupEvents();
        }
    }, []);
    const replaceEvent = useCallback((newState) => {
        const targetEvent = managedEvents.current.get(newState.key);
        if (targetEvent) {
            targetEvent.status = newState.status;
            targetEvent.start = newState.start;
            targetEvent.end = newState.end;
            targetEvent.assignee = newState.assignee;
            groupEvents();
        }
    }, []);
    const adjustEventTime = useCallback((key, destinationTime, type) => {
        const targetEvent = managedEvents.current.get(key);
        if (targetEvent) {
            targetEvent.start = type === 'start' ? destinationTime : targetEvent.start;
            targetEvent.end = type === 'end' ? destinationTime : targetEvent.end;
            groupEvents();
        }
    }, []);
    const removeEvent = useCallback((key) => {
        const targetEvent = managedEvents.current.get(key);
        if (targetEvent) {
            managedEvents.current.delete(key);
            groupEvents();
        }
    }, []);
    const addEvent = useCallback((event) => {
        managedEvents.current.set(event.key, event);
        groupEvents();
    }, []);
    const saveEvent = useCallback((key) => {
        const targetEvent = managedEvents.current.get(key);
        if (targetEvent) {
            setLoading(true);
            saveVisit(targetEvent);
        }
    }, []);
    useEffect(() => {
        if (savedVisit) {
            setLastModifiedEvent(savedVisit);
            setLoading(false);
        }
    }, [savedVisit]);
    return {
        loading,
        groupedEvents,
        lastModifiedEvent,
        actions: { moveEvent, reassignEvent, replaceEvent, adjustEventTime, saveEvent, addEvent, removeEvent }
    };
};
export default useEventManagerOld;
