import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { actionTypes } from '../reducer';
import FilterTypes from '../../../constants/FilterTypes.ts';

export default ({
  filters,
  dispatch,
  optionalFilters,
  frequenciesForSelect,
  inspectionTypesForSelect,
  territoriesForSelect,
  subcontractorsForSelect,
  assetsForSelect,
  buildingId,
  inspectionSeriesId,
  accountId
}) => {
  const filtersList = [
    {
      field: 'scheduledDate',
      locale: 'scheduled_date',
      type: 'dateRangePicker',
      active: filters.active.scheduledDate,
      values: filters.values.scheduledDate,
      onChange: onChangeDateFilter('scheduledDate', dispatch),
      onToggle: onToggleFilter('scheduledDate', dispatch)
    },
    {
      field: 'frequency',
      locale: 'frequency',
      type: 'commonSelect',
      active: filters.active.frequency,
      optionsForSelect: frequenciesForSelect,
      value: filters.values.frequency,
      onChange: onChangeReactFilter('frequency', dispatch),
      onToggle: onToggleFilter('frequency', dispatch)
    },
    {
      field: 'building',
      locale: 'building',
      type: buildingId || inspectionSeriesId ? 'hidden' : FilterTypes.GenericMultiSelect,
      active: filters.active.building,
      value: filters.values.building,
      onChange: handleMultiSelectFilterChange('building', dispatch),
      onToggle: onToggleFilter('building', dispatch)
    },
    {
      field: 'status',
      locale: 'status',
      type: 'multiSelect',
      active: filters.active.status,
      value: filters.values.status,
      onChange: onChangeSearchFilter('status', dispatch),
      onToggle: onToggleFilter('status', dispatch),
      tagTemplate: statusTagTemplate,
      itemTemplate: statusItemTemplate,
      withIcons: true
    },
    {
      field: 'technician',
      locale: 'technician',
      type: 'multiSelectWithAvatar',
      active: filters.active.technician,
      value: filters.values.technician,
      withUnassigned: true,
      onlyFullTech: true,
      onChange: onChangeSearchFilter('technician', dispatch),
      onToggle: onToggleFilter('technician', dispatch)
    },
    {
      field: 'technicianTeam',
      locale: 'technician_team',
      type: FilterTypes.GenericMultiSelect,
      active: filters.active.technicianTeam,
      value: filters.values.technicianTeam,
      onChange: handleMultiSelectFilterChange('technicianTeam', dispatch),
      onToggle: onToggleFilter('technicianTeam', dispatch)
    },
    {
      field: 'asset',
      locale: 'asset',
      type: FilterTypes.TreeMultiSelect,
      active: filters.active.asset,
      value: filters.values.asset,
      optionsForSelect: assetsForSelect,
      onChange: handleMultiSelectFilterChange('asset', dispatch),
      onToggle: onToggleFilter('asset', dispatch)
    },
    {
      field: 'territory',
      locale: 'territory',
      type: FilterTypes.GenericMultiSelect,
      active: filters.active.territory,
      optionsForSelect: territoriesForSelect,
      value: filters.values.territory,
      onChange: handleMultiSelectFilterChange('territory', dispatch),
      onToggle: onToggleFilter('territory', dispatch)
    },
    {
      field: 'buildingStatus',
      locale: 'building_status',
      type: buildingId || inspectionSeriesId ? 'hidden' : 'booleanSelect',
      active: buildingId || inspectionSeriesId ? false : filters.active.buildingStatus,
      value: filters.values.buildingStatus,
      onChange: onChangeReactFilter('buildingStatus', dispatch),
      onToggle: onToggleFilter('buildingStatus', dispatch)
    },
    {
      field: 'account',
      locale: 'account',
      type: accountId ? 'hidden' : FilterTypes.GenericMultiSelect,
      active: filters.active.account,
      value: filters.values.account,
      onChange: handleMultiSelectFilterChange('account', dispatch),
      onToggle: onToggleFilter('account', dispatch)
    },
    {
      field: 'noAccess',
      locale: 'no_access',
      type: 'booleanSelect',
      active: filters.active.noAccess,
      value: filters.values.noAccess,
      onChange: onChangeReactFilter('noAccess', dispatch),
      onToggle: onToggleFilter('noAccess', dispatch)
    },
    {
      field: 'zipCode',
      locale: 'zip_code',
      type: 'textBox',
      active: filters.active.zipCode,
      value: filters.values.zipCode,
      onChange: onChangeReactFilter('zipCode', dispatch),
      onToggle: onToggleFilter('zipCode', dispatch)
    },
    {
      field: 'referenceNumber',
      locale: 'reference_number',
      type: 'textBox',
      active: filters.active.referenceNumber,
      value: filters.values.referenceNumber,
      onChange: onChangeReactFilter('referenceNumber', dispatch),
      onToggle: onToggleFilter('referenceNumber', dispatch)
    },
    {
      field: 'workOrder',
      locale: 'work_order',
      type: 'textBox',
      active: filters.active.workOrder,
      value: filters.values.workOrder,
      onChange: onChangeReactFilter('workOrder', dispatch),
      onToggle: onToggleFilter('workOrder', dispatch)
    },
    {
      field: 'tags',
      locale: 'tags',
      type: FilterTypes.GenericMultiSelect,
      active: filters.active.tags,
      value: filters.values.tags,
      onChange: handleMultiSelectFilterChange('tags', dispatch),
      onToggle: onToggleFilter('tags', dispatch)
    },
    {
      field: 'inspectionDate',
      locale: 'inspection_date',
      type: 'dateRangePicker',
      active: filters.active.inspectionDate,
      values: filters.values.inspectionDate,
      onChange: onChangeDateFilter('inspectionDate', dispatch),
      onToggle: onToggleFilter('inspectionDate', dispatch)
    },
    {
      field: 'dueDate',
      locale: 'due_date',
      type: 'dateRangePicker',
      active: filters.active.dueDate,
      values: filters.values.dueDate,
      onChange: onChangeDateFilter('dueDate', dispatch),
      onToggle: onToggleFilter('dueDate', dispatch)
    }
  ];

  if (optionalFilters.subcontractor) {
    filtersList.push({
      field: 'subcontractor',
      locale: 'subcontractor',
      type: 'treeSelect',
      active: filters.active.subcontractor,
      optionsForSelect: subcontractorsForSelect,
      value: filters.values.subcontractor,
      onChange: onChangeReactFilter('subcontractor', dispatch),
      onToggle: onToggleFilter('subcontractor', dispatch)
    });
  }

  if (optionalFilters.tce) {
    filtersList.push({
      field: 'tce',
      locale: 'tce',
      type: 'checkbox',
      active: filters.active.tce,
      value: filters.values.tce,
      onChange: onChangeReactFilter('tce', dispatch),
      onToggle: onToggleFilter('tce', dispatch)
    });
  }

  if (optionalFilters.inspectionType) {
    filtersList.push({
      field: 'inspectionType',
      locale: 'inspection_type',
      type: 'commonSelect',
      active: filters.active.inspectionType,
      optionsForSelect: inspectionTypesForSelect,
      value: filters.values.inspectionType,
      onChange: onChangeReactFilter('inspectionType', dispatch),
      onToggle: onToggleFilter('inspectionType', dispatch)
    });
  }

  return filtersList;
};

const onToggleFilter = (fieldName, dispatch) => {
  return () => {
    dispatch({ type: actionTypes.FILTER_TOGGLED, field: fieldName });
  };
};

const onChangeDateFilter = (fieldName, dispatch) => {
  return (dateFieldName, value) => {
    dispatch({
      type: actionTypes.DATE_RANGE_FILTER_CHANGED,
      field: fieldName,
      dateField: dateFieldName,
      value
    });
  };
};

const onChangeSearchFilter = (fieldName, dispatch) => {
  return (e) => {
    dispatch({ type: actionTypes.FILTER_CHANGED, value: e.sender.value(), field: fieldName });
  };
};

const handleMultiSelectFilterChange = (fieldName, dispatch) => {
  return (value) => {
    dispatch({ type: actionTypes.FILTER_CHANGED, value, field: fieldName });
  };
};

const onChangeReactFilter = (fieldName, dispatch) => {
  return (value) => {
    dispatch({ type: actionTypes.FILTER_CHANGED, value, field: fieldName });
  };
};

const statusTagTemplate = ReactDOMServer.renderToString(
  <span className="qmb-avatar--24--status-icon">
    <i className="fa-lg #: icon #" style={{ color: '#: color #' }} />
  </span>
);

const statusItemTemplate = ReactDOMServer.renderToString(
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <span
      className="qmb-avatar--24--status-icon"
      style={{ display: 'flex', borderRadius: '50%', alignItems: 'center', justifyContent: 'center' }}>
      <i className="fa-lg #: icon #" style={{ color: '#: color #' }} />
    </span>
    <div style={{ marginLeft: '0.8rem' }}>#: label #</div>
  </div>
);
