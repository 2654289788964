import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { getTimeAsPercentageOfDay } from '@utils/functions/scheduler.functions';
import { isVisit } from '@utils/typeGuards/scheduler-event-typeguard';
import { SchedulerContext } from '../../Contexts/SchedulerContext';
import { RearrangeContext } from '../../Contexts/RearrangeContext';
import { ScheduleAndDispatchContext } from '../../Contexts/ScheduleAndDispatchContext';
export const EventContext = createContext(undefined);
export function EventContextProvider(params) {
    const { activeEvent, activeDate, setActiveSchedulerEvent } = useContext(SchedulerContext);
    const { startRearrangingVisit, startTimeAdjustment, stopRearranging } = useContext(RearrangeContext);
    const { openVisitModal, saveVisit } = useContext(ScheduleAndDispatchContext);
    const [eventState, setEventState] = useState(0 /* EventState.IDLE */);
    const handleEventMouseUp = useCallback((e) => {
        if (isVisit(params.event)) {
            setEventState(0 /* EventState.IDLE */);
            stopRearranging();
            saveVisit(params.event);
            setActiveSchedulerEvent(undefined);
        }
    }, [params.event, saveVisit]);
    useEffect(() => {
        var _a;
        if (((_a = activeEvent.current) === null || _a === void 0 ? void 0 : _a.key) === params.event.key && eventState !== 0 /* EventState.IDLE */) {
            document.addEventListener('mouseup', handleEventMouseUp);
        }
        return () => {
            document.removeEventListener('mouseup', handleEventMouseUp);
        };
    }, [activeEvent.current, params.event, eventState]);
    useEffect(() => {
        var _a;
        // If newly mounted component for a event currently being rearranged, continue  rearranging
        if (((_a = activeEvent.current) === null || _a === void 0 ? void 0 : _a.key) === params.event.key && isVisit(params.event)) {
            setEventState(1 /* EventState.REPOSITIONING */);
            startRearrangingVisit(params.event, true);
        }
    }, []);
    const metadata = useMemo(() => {
        const startTimeAsPercentage = getTimeAsPercentageOfDay(params.event.start, activeDate);
        const endTimeAsPercentage = getTimeAsPercentageOfDay(params.event.end, activeDate);
        return {
            startTimeAsPercentage,
            endTimeAsPercentage,
            overflowPrevDay: startTimeAsPercentage === 0,
            overflowNextDay: endTimeAsPercentage === 0
        };
    }, [params.event.start, params.event.end, activeDate]);
    // useEffect(() => {
    //   console.log(targetVisit);
    //   // if (targetVisit?.key === params.visit.key) setEventState(EventState.REPOSITIONING);
    // }, [targetVisit]);
    const mouseDownTimer = useRef(null);
    const handleMouseUp = useCallback(() => {
        setEventState(0 /* EventState.IDLE */);
        document.removeEventListener('mouseup', handleMouseUp);
    }, []);
    const handleMouseDown = useCallback((e) => {
        mouseDownTimer.current = setTimeout(() => {
            if (isVisit(params.event)) {
                setActiveSchedulerEvent(params.event);
                setEventState(1 /* EventState.REPOSITIONING */);
                mouseDownTimer.current = null;
                startRearrangingVisit(params.event, true);
            }
        }, 200);
        document.addEventListener('mouseup', () => {
            if (mouseDownTimer.current) {
                clearTimeout(mouseDownTimer.current);
                mouseDownTimer.current = null;
                if (isVisit(params.event))
                    openVisitModal(params.event.type, params.event.id);
            }
        }, { once: true });
        // document.addEventListener('mouseup', handleMouseUp, { once: true });
        // @ts-ignore
        // mouseUpListener.current = handleMouseUp;
    }, [params.event]);
    const handleResizeMouseDown = useCallback((timeType, snap) => {
        if (isVisit(params.event)) {
            setEventState(2 /* EventState.RESIZING */);
            startTimeAdjustment(params.event, timeType, snap);
            // document.addEventListener('mouseup', handleMouseUp, { once: true });
        }
    }, [params.event]);
    // useEffect(() => {
    //   // Event Listener Cleanup
    //   return () => {
    //     if (mouseUpListener.current){
    //       console.log('cleaning up events');
    //       document.removeEventListener('mouseup', mouseUpListener.current);
    //       mouseUpListener.current = null;
    //     }
    //   };
    // }, []);
    const contextData = useMemo(() => {
        const value = {
            event: params.event,
            clusterInfo: params.clusterInfo,
            state: eventState,
            metadata,
            handleMouseDown,
            handleResizeMouseDown
        };
        return value;
    }, [params.event, params.clusterInfo, eventState, metadata]);
    return React.createElement(EventContext.Provider, { value: contextData }, params.children);
}
