import React, { useContext, useEffect, useMemo, useState } from 'react';
import { DatePicker, TimePicker } from '@progress/kendo-react-dateinputs';
import { Duration } from 'luxon';
import { translate } from '@utils/i18n';
import api from 'axios';
import { isServiceVisit } from '@utils/typeGuards/visit.typeguard';
import dayjs from 'dayjs';
import { checkAvailableTechniciansPath } from '../../../../routes';
import TechnicianDropdown from '../../../../components/Dropdowns/TechnicianDropdown';
import SubcontractorDropdown from '../../../../components/Dropdowns/SubcontractorDropdown';
import TechSubToggle from './tech-sub-toggle';
import Error from '../../../../components/Error';
import { VisitModalContext } from '../VisitModalContext';
import VisitStatusDropdown from '../../../../components/Dropdowns/VisitStatusDropdown';
import { Loader, LoaderSizes } from '../../../../components/Loader/Loader';
function Form(params) {
    const namespace = 'features.modals.visit_modal.form';
    const { tenant, visit, visitStatusList, subcontractorList, technicianList, saving, closeModal, saveVisit } = useContext(VisitModalContext);
    if (!visit || !tenant)
        return React.createElement(Error, { error: "Unable to load form." });
    const [formData, setFormData] = useState(visit);
    const [availabilityNotification, setAvailabilityNotification] = useState();
    const handleStatusChange = (newStatus) => {
        setFormData((prevState) => (Object.assign(Object.assign({}, prevState), { status: newStatus })));
    };
    const handleAssigneeTypeChange = (type) => {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        const newAssignee = type === "subcontractor" /* AssigneeType.SUBCONTRACTOR */
            ? {
                type: "subcontractor" /* AssigneeType.SUBCONTRACTOR */,
                key: `${"subcontractor" /* AssigneeType.SUBCONTRACTOR */}-${(_b = (_a = visit.assignee.subcontractor) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : 'unassigned'}`,
                info: (_c = visit.assignee.subcontractor) !== null && _c !== void 0 ? _c : null,
                subcontractor: (_d = visit.assignee.subcontractor) !== null && _d !== void 0 ? _d : null
            }
            : {
                type: "technician" /* AssigneeType.TECHNICIAN */,
                key: `${"technician" /* AssigneeType.TECHNICIAN */}-${(_f = (_e = visit.assignee.technician) === null || _e === void 0 ? void 0 : _e.id) !== null && _f !== void 0 ? _f : 'unassigned'}`,
                info: (_g = visit.assignee.technician) !== null && _g !== void 0 ? _g : null,
                technician: (_h = visit.assignee.technician) !== null && _h !== void 0 ? _h : null
            };
        if (isServiceVisit(visit)) {
            setFormData((prevState) => (Object.assign(Object.assign({}, prevState), { assignee: newAssignee, poNumber: undefined, billingLimit: undefined })));
        }
        else {
            setFormData((prevState) => (Object.assign(Object.assign({}, prevState), { assignee: newAssignee })));
        }
    };
    const handleSubcontractorChange = (subcontractor) => {
        setFormData((prevState) => {
            var _a;
            return (Object.assign(Object.assign({}, prevState), { assignee: { type: "subcontractor" /* AssigneeType.SUBCONTRACTOR */, key: `${"subcontractor" /* AssigneeType.SUBCONTRACTOR */}-${(_a = subcontractor === null || subcontractor === void 0 ? void 0 : subcontractor.id) !== null && _a !== void 0 ? _a : 'unassigned'}`, info: subcontractor, subcontractor } }));
        });
    };
    const handleTechnicianChange = (technician) => {
        setFormData((prevState) => {
            var _a;
            return (Object.assign(Object.assign({}, prevState), { assignee: { type: "technician" /* AssigneeType.TECHNICIAN */, key: `${"technician" /* AssigneeType.TECHNICIAN */}-${(_a = technician === null || technician === void 0 ? void 0 : technician.id) !== null && _a !== void 0 ? _a : 'unassigned'}`, info: technician, technician } }));
        });
    };
    const handlePONumberChange = (value) => {
        setFormData((prevState) => (Object.assign(Object.assign({}, prevState), { poNumber: value })));
    };
    const handleBillingLimitChange = (value) => {
        setFormData((prevState) => (Object.assign(Object.assign({}, prevState), { billingLimit: value })));
    };
    const handleDateChange = (e) => {
        setFormData((prevState) => (Object.assign(Object.assign({}, prevState), { start: dayjs(e.value) })));
    };
    const handleDurationChange = (newDuration) => {
        setFormData((old) => (Object.assign(Object.assign({}, old), { durationMins: Duration.fromObject(Object.assign({ days: duration.days, hours: duration.hours, minutes: duration.minutes }, newDuration)).as('minutes') })));
    };
    const duration = useMemo(() => Duration.fromObject({ minutes: formData.durationMins }).shiftTo('days', 'hours', 'minutes'), [formData.durationMins]);
    const assigneeDropdown = useMemo(() => {
        var _a, _b, _c;
        if (formData.assignee.subcontractor !== undefined)
            return (React.createElement(SubcontractorDropdown, { subcontractorList: subcontractorList, selectedId: (_b = (_a = formData.assignee) === null || _a === void 0 ? void 0 : _a.subcontractor) === null || _b === void 0 ? void 0 : _b.id, disabled: params.readonly, onChange: handleSubcontractorChange }));
        return (React.createElement(TechnicianDropdown, { technicianList: technicianList, selectedId: (_c = formData.assignee.technician) === null || _c === void 0 ? void 0 : _c.id, disabled: params.readonly, onChange: handleTechnicianChange }));
    }, [formData.assignee]);
    const subcontractorOnlyFields = useMemo(() => {
        if (formData.assignee.subcontractor !== undefined && isServiceVisit(formData)) {
            return (React.createElement("fieldset", { className: "form__set--horizontal--compact" },
                React.createElement("div", { className: "qmb-input--text--x-half form__field" },
                    React.createElement("input", { id: "po_number", value: formData.poNumber, onChange: (e) => handlePONumberChange(e.target.value), disabled: params.readonly }),
                    React.createElement("label", { className: "qmb-label", htmlFor: "po_number" }, "PO #")),
                React.createElement("div", { className: "qmb-input--text--x-half form__field" },
                    React.createElement("input", { id: "billing_limit", type: "number", min: "0", value: formData.billingLimit, onChange: (e) => handleBillingLimitChange(Number(e.target.value)), disabled: params.readonly }),
                    React.createElement("label", { className: "qmb-label", htmlFor: "billing_limit" }, "Billing Limit"))));
        }
        return null;
    }, [formData.assignee]);
    const assignedTypeToggleValue = useMemo(() => {
        if (formData.assignee.subcontractor !== undefined)
            return "subcontractor" /* AssigneeType.SUBCONTRACTOR */;
        return "technician" /* AssigneeType.TECHNICIAN */;
    }, [formData.assignee]);
    const AvailabilityContent = useMemo(() => {
        if (availabilityNotification) {
            return (React.createElement("div", { style: { height: '40px', margin: 'auto' } },
                availabilityNotification.message,
                React.createElement("br", null),
                availabilityNotification.data && (React.createElement("a", { href: availabilityNotification.data.link, target: "_blank", rel: "noreferrer" }, availabilityNotification.data.text_link))));
        }
        return null;
    }, [availabilityNotification]);
    useEffect(() => {
        setAvailabilityNotification(undefined);
        if (formData.assignee.technician) {
            api
                .get(checkAvailableTechniciansPath({ format: 'json' }), {
                params: {
                    default_object_name: visit.type,
                    default_object_id: visit.id,
                    technician_id: formData.assignee.technician.id,
                    scheduled_date: formData.start,
                    duration: formData.durationMins
                }
            })
                .catch((error) => {
                setAvailabilityNotification({
                    message: error.response.data.message,
                    data: error.response.data.data
                });
            });
        }
    }, [formData.start, formData.assignee]);
    return (React.createElement("div", { className: "dialog__section" },
        React.createElement("div", { className: "dialog__header--section" },
            React.createElement("h2", { className: "dialog__title" }, translate('scheduling_details', { namespace }))),
        React.createElement(TechSubToggle, { render: tenant.flags.subcontractorsEnabled, type: assignedTypeToggleValue, onChange: handleAssigneeTypeChange }),
        React.createElement("fieldset", { className: "form__set--vertical--compact" },
            assigneeDropdown,
            React.createElement(VisitStatusDropdown, { statusList: visitStatusList, selectedElement: formData.status, disabled: params.readonly, onChange: handleStatusChange })),
        subcontractorOnlyFields,
        React.createElement("fieldset", { className: "form__set--horizontal--compact" },
            React.createElement("div", { className: "qmb-input--text--x-half form__field" },
                React.createElement(DatePicker, { value: formData.start.toDate(), onChange: handleDateChange, disabled: params.readonly }),
                React.createElement("label", { className: "qmb-label" }, translate('scheduled_date', { namespace }))),
            React.createElement("div", { className: "qmb-input--text--x-half form__field" },
                React.createElement(TimePicker, { value: formData.start.toDate(), onChange: handleDateChange, disabled: params.readonly }),
                React.createElement("label", { className: "qmb-label" }, translate('scheduled_time', { namespace })))),
        React.createElement("fieldset", { className: "form__set--horizontal--compact" },
            React.createElement("div", { className: "form__field--x-quarter" },
                React.createElement("span", { className: "qmb-label", style: { width: '100%' } }, translate('duration', { namespace }))),
            React.createElement("div", { className: "qmb-input--number--x-quarter duration-field" },
                React.createElement("input", { type: "number", id: "duration_days", min: "0", value: duration.days, onChange: (e) => handleDurationChange({ days: e.target.value || 0 }), disabled: params.readonly }),
                React.createElement("label", { className: "qmb-label", htmlFor: "duration_days" }, translate('days', { namespace }))),
            React.createElement("div", { className: "qmb-input--number--x-quarter duration-field" },
                React.createElement("input", { type: "number", id: "duration_hours", min: "0", max: "23", value: duration.hours, onChange: (e) => handleDurationChange({ hours: e.target.value || 0 }), disabled: params.readonly }),
                React.createElement("label", { className: "qmb-label", htmlFor: "duration_hours" }, translate('hours', { namespace }))),
            React.createElement("div", { className: "qmb-input--number--x-quarter duration-field" },
                React.createElement("input", { type: "number", id: "duration_mins", min: "0", max: "59", value: duration.minutes, onChange: (e) => handleDurationChange({ minutes: e.target.value || 0 }), disabled: params.readonly }),
                React.createElement("label", { className: "qmb-label", htmlFor: "duration_mins" }, translate('minutes', { namespace })))),
        React.createElement("div", { className: "modal__footer", style: { marginTop: '16px' } },
            AvailabilityContent,
            React.createElement("div", { style: { height: '40px', margin: 'auto' } }),
            React.createElement("button", { type: "button", className: "qmb-button", id: "charge_customer_cancel_btn", onClick: closeModal }, translate('cancel', { namespace })),
            !params.readonly && (React.createElement("button", { type: "button", className: "qmb-button--submit", onClick: () => saveVisit(formData), disabled: params.readonly }, saving ? React.createElement(Loader, { size: LoaderSizes.small }) : translate('save', { namespace }))))));
}
export default Form;
