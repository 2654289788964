import React, { useState, useMemo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import I18n from '@utils/i18n';

const priceListInput = document.querySelector('[name*="proposal[price_list_id]"');

function PriceFactorSelector({ name, optionsForSelect, proposalFactorId: initialProposalFactorId, priceListFactors }) {
  const [priceListId, setPriceListId] = useState(parseInt(priceListInput.value, 10));
  const [proposalFactorId, setProposalFactorId] = useState(initialProposalFactorId);
  const selector = useRef();

  useEffect(() => {
    priceListInput.addEventListener('change', function () {
      setPriceListId(parseInt(this.value, 10));
    });
  }, []);

  useEffect(() => {
    selector.current.dispatchEvent(new Event('change', { bubbles: true }));
  }, [])

  const options = useMemo(() => {
    if (Number.isNaN(priceListId)) return optionsForSelect;
    return optionsForSelect.filter((option) => priceListFactors[priceListId].includes(option[1]));
  }, [priceListId]);

  return (
    <select
      ref={selector}
      data-js-proposal-factor="true"
      name={name}
      value={proposalFactorId}
      onChange={(e) => setProposalFactorId(e.target.value)}>
      <option value="">{I18n.t('proposals.v2.fee.choose_factor')}</option>
      {options.map((option) => (
        <option key={option[1]} value={option[1]} {...option[2]}>
          {option[0]}
        </option>
      ))}
    </select>
  );
}

PriceFactorSelector.propTypes = {
  name: PropTypes.string.isRequired,
  optionsForSelect: PropTypes.array.isRequired,
  proposalFactorId: PropTypes.number.isRequired,
  priceListFactors: PropTypes.array.isRequired
};

export default PriceFactorSelector;
