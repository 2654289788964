/* eslint-disable */
function CalculateClustering(events) {
    const metadata = {};
    if (events.length === 0)
        return metadata;
    const sortedEvents = events.sort((a, b) => a.start.diff(b.start));
    const clusters = [];
    let currentCluster = [];
    let currentClusterEndTime = null;
    let i = 0;
    // Cluster the events
    do {
        const event = sortedEvents[i];
        if (metadata[event.key] === undefined) {
            metadata[event.key] = {};
        }
        if (currentClusterEndTime === null ||
            event.start.isSame(currentClusterEndTime) ||
            event.start.isAfter(currentClusterEndTime)) {
            // Push finished cluster into reserve and start next one
            clusters.push(currentCluster);
            currentCluster = [];
            currentClusterEndTime = event.end;
        }
        if ((event.start.isBefore(currentClusterEndTime) || event.start.isSame(currentClusterEndTime)) &&
            (event.end.isAfter(currentClusterEndTime) || event.end.isSame(currentClusterEndTime))) {
            // Push the current cluster's end time if last event in cluster extends beyond current end time
            currentClusterEndTime = event.end;
        }
        currentCluster.push(event);
        if (i === sortedEvents.length - 1) {
            clusters.push(currentCluster);
        }
        i++;
    } while (i < sortedEvents.length);
    clusters.forEach((cluster, index) => {
        let maxConsecutiveRows = 1;
        let currentRow = 1;
        for (let i = 0; i < cluster.length; i++) {
            const event = cluster[i];
            const nextEvent = cluster[i + 1];
            if (nextEvent && (nextEvent.start.isBefore(event.end) || nextEvent.start.isSame(event.end))) {
                currentRow++;
                if (currentRow > maxConsecutiveRows) {
                    maxConsecutiveRows = currentRow;
                }
            }
            else {
                currentRow = 1;
            }
            if (cluster.length === 0)
                return;
            metadata[cluster[0].key].cluster = index;
            metadata[cluster[0].key].subdivision = maxConsecutiveRows;
            metadata[cluster[0].key].offset = 0;
            metadata[cluster[0].key].span = 1;
            // eslint-disable-next-line @typescript-eslint/no-shadow
            for (let i = 1; i < cluster.length; i++) {
                const prevEvent = cluster[i - 1];
                const currEvent = cluster[i];
                const nextEvent = cluster[i + 1];
                const prevMetadata = metadata[prevEvent.key];
                const currMetadata = metadata[currEvent.key];
                currMetadata.cluster = index;
                currMetadata.subdivision = maxConsecutiveRows;
                if (prevMetadata.offset + prevMetadata.span === maxConsecutiveRows) {
                    // Find base
                    let found = false;
                    for (let j = i - 1; j >= 0; j--) {
                        const x = cluster[j];
                        if (currEvent.start.isBefore(x.end)) {
                            found = true;
                            currMetadata.offset = (metadata[x.key].offset + 1) % maxConsecutiveRows;
                            break;
                        }
                        if (!found) {
                            currMetadata.offset = 0;
                        }
                    }
                }
                else {
                    currMetadata.offset = prevMetadata.offset + prevMetadata.span;
                }
                if (nextEvent) {
                    if (nextEvent.start.isAfter(currEvent.end)) {
                        currMetadata.span = maxConsecutiveRows - currMetadata.offset;
                    }
                    else {
                        currMetadata.span = 1;
                    }
                }
                else if (currMetadata.offset === 0)
                    currMetadata.span = 1;
                else
                    currMetadata.span = maxConsecutiveRows - currMetadata.offset;
            }
        }
    });
    // console.log('clusters:');
    // console.log(metadata);
    return metadata;
}
export default CalculateClustering;
