import { useMemo } from 'react';
import { actionTypes } from '../reducer';
import FilterTypes from '../../../constants/FilterTypes.ts';

export default function useFilters({
  filters,
  dispatch,
  territoriesForSelect,
  resolutionStatusesForSelect,
  deficiencyStatusesForSelect,
  salesPeopleForSelect,
  assetsForSelect,
  screen
}) {
  const onToggleFilter = (fieldName) => {
    return () => {
      dispatch({ type: actionTypes.FILTER_TOGGLED, field: fieldName });
    };
  };

  const onChangeDateFilter = (fieldName) => {
    return (dateFieldName, value) => {
      dispatch({
        type: actionTypes.DATE_RANGE_FILTER_CHANGED,
        field: fieldName,
        dateField: dateFieldName,
        value
      });
    };
  };

  const onChangeSearchFilter = (fieldName) => {
    return (e) => {
      dispatch({ type: actionTypes.FILTER_CHANGED, value: e.sender.value(), field: fieldName });
    };
  };

  const handleMultiSelectFilterChange = (fieldName) => {
    return (value) => {
      dispatch({ type: actionTypes.FILTER_CHANGED, value, field: fieldName });
    };
  };

  const onChangeReactFilter = (fieldName) => {
    return (value) => {
      dispatch({ type: actionTypes.FILTER_CHANGED, value, field: fieldName });
    };
  };

  const indexFilterList = () => [
    {
      field: 'resolved',
      locale: 'resolved',
      type: 'booleanSelect',
      active: filters.active.resolved,
      value: filters.values.resolved,
      onChange: onChangeReactFilter('resolved'),
      onToggle: onToggleFilter('resolved')
    },
    {
      field: 'dateOpened',
      locale: 'date_opened',
      type: 'dateRangePicker',
      active: filters.active.dateOpened,
      values: filters.values.dateOpened,
      onChange: onChangeDateFilter('dateOpened'),
      onToggle: onToggleFilter('dateOpened')
    },
    {
      field: 'building',
      locale: 'building',
      type: FilterTypes.GenericMultiSelect,
      active: filters.active.building,
      value: filters.values.building,
      onChange: handleMultiSelectFilterChange('building'),
      onToggle: onToggleFilter('building')
    },
    {
      field: 'account',
      locale: 'account',
      type: FilterTypes.GenericMultiSelect,
      active: filters.active.account,
      value: filters.values.account,
      onChange: handleMultiSelectFilterChange('account'),
      onToggle: onToggleFilter('account')
    },
    {
      field: 'territory',
      locale: 'territory',
      type: FilterTypes.GenericMultiSelect,
      active: filters.active.territory,
      optionsForSelect: territoriesForSelect,
      value: filters.values.territory,
      onChange: handleMultiSelectFilterChange('territory'),
      onToggle: onToggleFilter('territory')
    },
    {
      field: 'assets',
      locale: 'asset',
      type: FilterTypes.TreeMultiSelect,
      active: filters.active.assets,
      value: filters.values.assets,
      optionsForSelect: assetsForSelect,
      onChange: handleMultiSelectFilterChange('assets'),
      onToggle: onToggleFilter('assets')
    },
    {
      field: 'resolutionStatus',
      locale: 'resolution_status',
      type: 'commonSelect',
      active: filters.active.resolutionStatus,
      optionsForSelect: resolutionStatusesForSelect,
      value: filters.values.resolutionStatus,
      onChange: onChangeReactFilter('resolutionStatus'),
      onToggle: onToggleFilter('resolutionStatus')
    },
    {
      field: 'deficiencyStatus',
      locale: 'deficiency_status',
      type: 'commonSelect',
      active: filters.active.deficiencyStatus,
      optionsForSelect: deficiencyStatusesForSelect,
      value: filters.values.deficiencyStatus,
      onChange: onChangeReactFilter('deficiencyStatus'),
      onToggle: onToggleFilter('deficiencyStatus')
    },
    {
      field: 'technician',
      backendField: 'technician',
      locale: 'reported_by',
      type: 'multiSelectWithAvatar',
      active: filters.active.technician,
      value: filters.values.technician,
      withUnassigned: true,
      onlyFullTech: true,
      onChange: onChangeSearchFilter('technician'),
      onToggle: onToggleFilter('technician')
    },
    {
      field: 'salesPerson',
      locale: 'sales_person',
      type: 'commonSelect',
      active: filters.active.salesPerson,
      optionsForSelect: salesPeopleForSelect,
      value: filters.values.salesPerson,
      onChange: onChangeReactFilter('salesPerson'),
      onToggle: onToggleFilter('salesPerson')
    }
  ];

  const inspectionFilterList = () => [
    {
      field: 'resolved',
      locale: 'resolved',
      type: 'booleanSelect',
      active: filters.active.resolved,
      value: filters.values.resolved,
      onChange: onChangeReactFilter('resolved'),
      onToggle: onToggleFilter('resolved')
    },
    {
      field: 'dateOpened',
      locale: 'date_opened',
      type: 'dateRangePicker',
      active: filters.active.dateOpened,
      values: filters.values.dateOpened,
      onChange: onChangeDateFilter('dateOpened'),
      onToggle: onToggleFilter('dateOpened')
    },
    {
      field: 'assets',
      locale: 'asset',
      type: FilterTypes.TreeMultiSelect,
      active: filters.active.assets,
      value: filters.values.assets,
      optionsForSelect: assetsForSelect,
      onChange: handleMultiSelectFilterChange('assets'),
      onToggle: onToggleFilter('assets')
    },
    {
      field: 'resolutionStatus',
      locale: 'resolution_status',
      type: 'commonSelect',
      active: filters.active.resolutionStatus,
      optionsForSelect: resolutionStatusesForSelect,
      value: filters.values.resolutionStatus,
      onChange: onChangeReactFilter('resolutionStatus'),
      onToggle: onToggleFilter('resolutionStatus')
    },
    {
      field: 'deficiencyStatus',
      locale: 'deficiency_status',
      type: 'commonSelect',
      active: filters.active.deficiencyStatus,
      optionsForSelect: deficiencyStatusesForSelect,
      value: filters.values.deficiencyStatus,
      onChange: onChangeReactFilter('deficiencyStatus'),
      onToggle: onToggleFilter('deficiencyStatus')
    },
    {
      field: 'technician',
      backendField: 'technician',
      locale: 'reported_by',
      type: 'multiSelectWithAvatar',
      active: filters.active.technician,
      value: filters.values.technician,
      withUnassigned: true,
      onlyFullTech: true,
      onChange: onChangeSearchFilter('technician'),
      onToggle: onToggleFilter('technician')
    },
    {
      field: screen,
      type: 'hidden',
      active: true,
      value: filters.values[screen]
    }
  ];

  const accountFilterList = () => [
    {
      field: 'resolved',
      locale: 'resolved',
      type: 'booleanSelect',
      active: filters.active.resolved,
      value: filters.values.resolved,
      onChange: onChangeReactFilter('resolved'),
      onToggle: onToggleFilter('resolved')
    },
    {
      field: 'dateOpened',
      locale: 'date_opened',
      type: 'dateRangePicker',
      active: filters.active.dateOpened,
      values: filters.values.dateOpened,
      onChange: onChangeDateFilter('dateOpened'),
      onToggle: onToggleFilter('dateOpened')
    },
    {
      field: 'building',
      locale: 'building',
      type: FilterTypes.GenericMultiSelect,
      active: filters.active.building,
      value: filters.values.building,
      onChange: handleMultiSelectFilterChange('building'),
      onToggle: onToggleFilter('building')
    },
    {
      field: screen,
      type: 'hidden',
      active: true,
      value: filters.values[screen]
    },
    {
      field: 'territory',
      locale: 'territory',
      type: FilterTypes.GenericMultiSelect,
      active: filters.active.territory,
      optionsForSelect: territoriesForSelect,
      value: filters.values.territory,
      onChange: handleMultiSelectFilterChange('territory'),
      onToggle: onToggleFilter('territory')
    },
    {
      field: 'assets',
      locale: 'asset',
      type: FilterTypes.TreeMultiSelect,
      active: filters.active.assets,
      value: filters.values.assets,
      optionsForSelect: assetsForSelect,
      onChange: handleMultiSelectFilterChange('assets'),
      onToggle: onToggleFilter('assets')
    },
    {
      field: 'resolutionStatus',
      locale: 'resolution_status',
      type: 'commonSelect',
      active: filters.active.resolutionStatus,
      optionsForSelect: resolutionStatusesForSelect,
      value: filters.values.resolutionStatus,
      onChange: onChangeReactFilter('resolutionStatus'),
      onToggle: onToggleFilter('resolutionStatus')
    },
    {
      field: 'deficiencyStatus',
      locale: 'deficiency_status',
      type: 'commonSelect',
      active: filters.active.deficiencyStatus,
      optionsForSelect: deficiencyStatusesForSelect,
      value: filters.values.deficiencyStatus,
      onChange: onChangeReactFilter('deficiencyStatus'),
      onToggle: onToggleFilter('deficiencyStatus')
    },
    {
      field: 'technician',
      backendField: 'technician',
      locale: 'reported_by',
      type: 'multiSelectWithAvatar',
      active: filters.active.technician,
      value: filters.values.technician,
      withUnassigned: true,
      onlyFullTech: true,
      onChange: onChangeSearchFilter('technician'),
      onToggle: onToggleFilter('technician')
    },
    {
      field: 'salesPerson',
      locale: 'sales_person',
      type: 'commonSelect',
      active: filters.active.salesPerson,
      optionsForSelect: salesPeopleForSelect,
      value: filters.values.salesPerson,
      onChange: onChangeReactFilter('salesPerson'),
      onToggle: onToggleFilter('salesPerson')
    }
  ];

  return useMemo(() => {
    if (screen === 'inspection' || screen === 'building' || screen === 'workOrder') {
      return inspectionFilterList();
    }

    if (screen === 'account') {
      return accountFilterList();
    }

    return indexFilterList();
  }, [filters]);
}
