import React, { createContext, useCallback, useMemo, useState } from 'react';
import useApiQuery from '@hooks/api-params.hook';
import TechnicianSortableAttributes from '@enums/sortable-attributes/technician-sortable-attributes.enum';
import useGetTechnicians from '@hooks/requests/technicians.get.hook';
import SubcontractorSortableAttributes from '@enums/sortable-attributes/subcontractor-sortable-attributes.enum';
import useGetSubcontractors from '@hooks/requests/subcontractors.get.hook';
import useGetTenantDetails from '@hooks/requests/tenant-details.get.hook';
const actionNotProvided = () => {
    console.warn('Action not provided to context');
};
const defaultContext = {
    multiSelectReady: false,
    tenant: null,
    activeMultiSelector: null,
    setActiveMultiSelector: actionNotProvided,
    setSelectedTechnicians: actionNotProvided,
    setSelectedSubcontractors: actionNotProvided,
    technicianList: [],
    selectedTechnicians: [],
    subcontractorList: [],
    selectedSubcontractors: []
};
export const AssigneeMultiSelectContext = createContext(defaultContext);
export function AssigneeMultiSelectProvider(params) {
    // Load Tenant Details
    const { data: tenant, loading: tenantLoading, error: tenantError } = useGetTenantDetails();
    // Fetch Technicians
    const { techsQuery, subsQuery } = useMemo(() => {
        var _a, _b;
        const t = {
            select: 'all',
            sort: { attribute: TechnicianSortableAttributes.NAME, direction: 'asc' },
            filter: {
                techTeamIds: (_a = params.teamIds) !== null && _a !== void 0 ? _a : undefined
            }
        };
        const s = {
            select: 'all',
            sort: { attribute: SubcontractorSortableAttributes.NAME, direction: 'asc' },
            filter: {
                techTeamIds: (_b = params.teamIds) !== null && _b !== void 0 ? _b : undefined
            }
        };
        return { techsQuery: t, subsQuery: s };
    }, [params.teamIds]);
    const { apiParams: techniciansQuery } = useApiQuery(techsQuery);
    const { data: technicianListFetch, loading: techniciansLoading, error: techniciansError } = useGetTechnicians(techniciansQuery);
    // Fetch Subcontractors
    const { apiParams: subcontractorsQuery } = useApiQuery(subsQuery);
    const { data: subcontractorListFetch, loading: subcontractorsLoading, error: subcontractorsError } = useGetSubcontractors(subcontractorsQuery);
    // Prepend with null for unassigned
    const technicianList = useMemo(() => {
        return params.teamIds && params.teamIds.length > 0 ? technicianListFetch : [null, ...technicianListFetch];
    }, [technicianListFetch, params.teamIds]);
    const subcontractorList = useMemo(() => {
        return params.teamIds && params.teamIds.length > 0 ? subcontractorListFetch : [null, ...subcontractorListFetch];
    }, [subcontractorListFetch, params.teamIds]);
    // Derived data
    const initializing = tenantLoading || techniciansLoading || subcontractorsLoading;
    const selectedTechnicians = useMemo(() => {
        const set = new Set(params.selectedTechnicianIds);
        return technicianList.filter((tech) => {
            if (tech === null)
                return set.has(null);
            return set.has(tech.id);
        });
    }, [params.selectedTechnicianIds, technicianList]);
    const selectedSubcontractors = useMemo(() => {
        const set = new Set(params.selectedSubcontractorIds);
        return subcontractorList.filter((sub) => {
            if (sub === null)
                return set.has(null);
            return set.has(sub.id);
        });
    }, [params.selectedSubcontractorIds, subcontractorList]);
    // State
    const [activeMultiSelector, setActiveMultiSelector] = useState(null);
    const setSelectedTechnicians = useCallback((selections) => {
        params.onTechChange(selections.map((x) => (x === null || x === void 0 ? void 0 : x.id) || null));
    }, []);
    const setSelectedSubcontractors = useCallback((selections) => {
        params.onSubcontractorChange(selections.map((x) => (x === null || x === void 0 ? void 0 : x.id) || null));
    }, []);
    const contextValue = useMemo(() => {
        const value = {
            multiSelectReady: !initializing,
            tenant: tenant !== null && tenant !== void 0 ? tenant : null,
            activeMultiSelector,
            technicianList,
            selectedTechnicians,
            subcontractorList,
            selectedSubcontractors,
            setActiveMultiSelector,
            setSelectedTechnicians,
            setSelectedSubcontractors
        };
        return value;
    }, [
        initializing,
        tenant,
        technicianList,
        selectedTechnicians,
        subcontractorList,
        selectedSubcontractors,
        activeMultiSelector
    ]);
    return (React.createElement(AssigneeMultiSelectContext.Provider, { value: contextValue }, params.children));
}
