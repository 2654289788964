import React, { useContext, useMemo, useRef } from 'react';
import { GlobalDragContext } from '../Contexts/GlobalDragContext';
import DraggableEvent from './Draggables/DraggableEvent';
function GlobalDragItem() {
    const { activeGlobalDragItem, currentMouseX, currentMouseY } = useContext(GlobalDragContext);
    const element = useRef(null);
    const dynamicStyle = useMemo(() => {
        return {
            left: `${currentMouseX - 25}px`,
            top: `${currentMouseY - 5}px`,
            position: 'fixed',
            pointerEvents: 'none',
            display: 'block'
        };
    }, [currentMouseX, currentMouseY]);
    const contents = useMemo(() => {
        return React.createElement(DraggableEvent, null);
    }, [activeGlobalDragItem]);
    return (React.createElement("div", { className: "workspace__draggable", ref: element, style: dynamicStyle }, contents));
}
export default GlobalDragItem;
