var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useMemo, useRef, useState } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import useDebounce from '@hooks/useDebounce';
import { Popup } from '@progress/kendo-react-popup';
import './TreeMultiSelect.scss';
import '../GenericMultiSelect.scss';
import useDebounceFn from '@hooks/useDebounceFn';
import { ExpansionPanel, ExpansionPanelContent } from '@progress/kendo-react-layout';
import { Reveal } from '@progress/kendo-react-animation';
import { DefaultDebouceDelay } from '../../../constants/configs';
function TreeMultiSelect(_a) {
    var { options } = _a, params = __rest(_a, ["options"]);
    const [searchText, setSearchText] = useState('');
    const [expandedOptions, setExpandedOptions] = useState([]);
    const searchValue = useDebounce(searchText, DefaultDebouceDelay);
    const onChangeDebounced = useDebounceFn(params.onChange, DefaultDebouceDelay);
    const [open, setOpen] = useState(false);
    const rootAnchor = useRef(null);
    const popup = useRef(null);
    const [internalValue, setInternalValue] = useState([]);
    useEffect(() => {
        const initialValues = Array.isArray(params.value) ? params.value : [params.value].filter(Boolean);
        if (initialValues.length === 0 && !open) {
            setSearchText('');
        }
        setInternalValue(initialValues);
    }, [params.value]);
    useEffect(() => {
        if (!open) {
            setSearchText('');
        }
    }, [open]);
    const onChangeOption = (option) => {
        let newValue;
        if (!option.items) {
            // It's not a parent option group
            const parent = options.find((opt) => { var _a; return (_a = opt.items) === null || _a === void 0 ? void 0 : _a.includes(option); });
            if (internalValue.includes(option.value)) {
                // If the option is selected directly just removed
                newValue = internalValue.filter((val) => val !== option.value);
            }
            else if (parent && internalValue.includes(parent === null || parent === void 0 ? void 0 : parent.value)) {
                // If the option is selected by its parent remove its parent and add all its siblings
                const subItemsRemoved = internalValue.filter((val) => val !== option.value && val !== (parent === null || parent === void 0 ? void 0 : parent.value));
                newValue = [
                    ...subItemsRemoved,
                    ...(parent.items || []).filter((opt) => opt.value !== option.value).map((val) => val.value)
                ];
            }
            else {
                // The option is not selected so just added
                newValue = [...internalValue, option.value];
            }
        }
        else {
            // It's a parent option group so remove/add all its children
            const subItemsRemoved = internalValue.filter((val) => ![option, ...(option.items || [])].map((item) => item.value).includes(val));
            newValue = internalValue.includes(option.value) ? [...subItemsRemoved] : [...subItemsRemoved, option.value];
        }
        setInternalValue(newValue);
        onChangeDebounced(newValue);
    };
    const handleClearFilter = () => {
        setSearchText('');
        setInternalValue([]);
        setOpen(false);
        onChangeDebounced([]);
    };
    const textValue = internalValue.length > 0
        ? internalValue
            .map((val) => { var _a; return (_a = [...options, ...options.flatMap((opt) => opt.items)].find((opt) => (opt === null || opt === void 0 ? void 0 : opt.value) === val)) === null || _a === void 0 ? void 0 : _a.label; })
            .join(', ')
        : params.label;
    const optionsFiltered = options.filter((opt) => {
        var _a;
        return opt.label.toLowerCase().includes(searchValue.toLowerCase()) ||
            ((_a = opt.items) === null || _a === void 0 ? void 0 : _a.some((item) => item.label.toLowerCase().includes(searchValue.toLowerCase())));
    });
    const onClickAway = (e) => {
        if (e.target !== rootAnchor.current) {
            setOpen(false);
        }
    };
    const itemsValueCount = useMemo(() => {
        return internalValue
            .map((val) => [...options, ...options.flatMap((opt) => opt.items)].find((opt) => (opt === null || opt === void 0 ? void 0 : opt.value) === val))
            .reduce((count, option) => {
            if (option === null || option === void 0 ? void 0 : option.items) {
                return count + option.items.length;
            }
            return count + 1;
        }, 0);
    }, [internalValue, options]);
    return (React.createElement(React.Fragment, null,
        React.createElement("button", { className: `qmb-control--menu multiSelectInput ${internalValue.length === 0 ? 'items-center' : ''}`, type: "button", ref: rootAnchor, onClick: () => setOpen(!open) }, textValue),
        internalValue.length > 0 && (React.createElement("label", { className: "qmb-label multiSelectInputLabel" },
            params.label,
            " ",
            React.createElement("b", null, itemsValueCount))),
        React.createElement(Popup, { anchor: rootAnchor.current, show: open, ref: popup },
            React.createElement(ClickAwayListener, { onClickAway: onClickAway },
                React.createElement("div", { className: "qmb-popup--actions--y-max multiSelectPopupContainer treeMultiSelectPopupContainer", style: { zIndex: 1000 } },
                    React.createElement("div", { className: "popup__section" },
                        React.createElement("input", { className: "searchInput", onChange: (e) => setSearchText(e.target.value), style: { border: 'none', padding: '1.2rem' }, placeholder: params.placeholder, value: searchText }),
                        React.createElement("i", { className: "fa-light fa-magnifying-glass searchIcon" })),
                    React.createElement("hr", { className: "popup__split" }),
                    React.createElement("div", { className: "popup__section--y-fill" },
                        React.createElement("ul", { key: "container", role: "presentation" },
                            React.createElement("div", { className: "messageContainer" }, optionsFiltered.length === 0 && React.createElement("span", { className: "noResults" }, "No results")),
                            optionsFiltered.map((option, idx) => {
                                var _a;
                                return !option.items ? (React.createElement("li", { key: `tech-option-${option.value}-idx-${idx}`, onClick: (e) => {
                                        if (e.detail !== 1)
                                            return;
                                        onChangeOption(option);
                                    } },
                                    React.createElement("label", { className: "qmb-checkbox" },
                                        React.createElement("input", { type: "checkbox", checked: internalValue.includes(option.value), readOnly: true }),
                                        React.createElement("p", { className: "qmb-checkbox-label", style: { padding: 0 }, onClick: (e) => {
                                                e.preventDefault();
                                            } },
                                            React.createElement("span", { className: "qmb-checkbox-title" }, option.label))))) : (React.createElement(ExpansionPanel, { title: React.createElement("label", { className: "qmb-checkbox" },
                                        React.createElement("input", { type: "checkbox", checked: internalValue.includes(option.value), readOnly: true, onClick: (e) => {
                                                e.stopPropagation();
                                                if (e.detail !== 1)
                                                    return;
                                                onChangeOption(option);
                                            } }),
                                        React.createElement("p", { className: "qmb-checkbox-label", style: { padding: 0 }, onClick: (e) => {
                                                e.preventDefault();
                                            } },
                                            React.createElement("span", { className: "qmb-checkbox-title" }, option.label))), expanded: expandedOptions.includes(option), key: `tech-option-${option.value}-idx-${idx}`, onAction: () => {
                                        setExpandedOptions((cur) => !expandedOptions.includes(option) ? [...cur, option] : cur.filter((opt) => opt !== option));
                                    } },
                                    React.createElement(Reveal, null, expandedOptions.includes(option) && (React.createElement(ExpansionPanelContent, null,
                                        React.createElement("ul", { key: "container", role: "presentation" }, (_a = option.items) === null || _a === void 0 ? void 0 : _a.map((opt, subIdx) => (React.createElement("li", { key: `tech-option-${opt.value}-idx-${subIdx}`, onClick: (e) => {
                                                if (e.detail !== 1)
                                                    return;
                                                onChangeOption(opt);
                                            } },
                                            React.createElement("label", { className: "qmb-checkbox" },
                                                React.createElement("input", { type: "checkbox", checked: internalValue.includes(opt.value) || internalValue.includes(option.value), readOnly: true }),
                                                React.createElement("p", { className: "qmb-checkbox-label", style: { padding: 0 }, onClick: (e) => {
                                                        e.preventDefault();
                                                    } },
                                                    React.createElement("span", { className: "qmb-checkbox-title" }, opt.label))))))))))));
                            }))),
                    React.createElement("hr", { className: "popup__split" }),
                    React.createElement("div", { className: "popup__section" },
                        React.createElement("ul", { role: "presentation" },
                            React.createElement("li", null,
                                React.createElement("div", { className: "messageContainer" },
                                    React.createElement("button", { type: "button", disabled: internalValue.length === 0, className: "qmb-control--sm--reset", onClick: handleClearFilter },
                                        React.createElement("i", { className: "fa-light fa-arrow-rotate-left" }),
                                        "Reset"))))))))));
}
export default TreeMultiSelect;
