import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
const setTimeZoneNotProvided = (timezone) => {
    console.log('Cannot set time zone. Missing action.');
};
const setMethodNotProvided = (method) => {
    console.log('Cannot set method. Missing action.');
};
const defaultContext = {
    timezone: 'America/New_York',
    method: 'dynamic',
    setTimeZone: setTimeZoneNotProvided,
    setMethod: setMethodNotProvided
};
export const TimeZoneContext = createContext(defaultContext);
export function TimeZoneContextProvider(params) {
    var _a;
    const [targetTimezone, setTargetTimezone] = useState((_a = params.initialTimezone) !== null && _a !== void 0 ? _a : 'America/New_York');
    const [targetMethod, setTargetMethod] = useState('fixed');
    const setTimeZone = useCallback((timezone) => {
        setTargetTimezone(timezone !== null && timezone !== void 0 ? timezone : Intl.DateTimeFormat().resolvedOptions().timeZone);
    }, []);
    const setMethod = useCallback((method) => {
        setTargetMethod(method);
    }, []);
    useEffect(() => {
        if (targetMethod === 'dynamic')
            setTargetTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
    }, [targetMethod]);
    const contextData = useMemo(() => {
        const value = {
            timezone: targetTimezone,
            method: targetMethod,
            setTimeZone,
            setMethod
        };
        return value;
    }, [targetTimezone, targetMethod]);
    return React.createElement(TimeZoneContext.Provider, { value: contextData }, params.children);
}
