import { useMemo } from 'react';
import useGetTimeOffs from '@hooks/requests/index/use-get-time-offs-hook';
import { isTechnician } from '@utils/typeGuards/assignee.typeguard';
const useGetSchedulerTimeOffs = (assignees, date) => {
    const timeOffsQuery = useMemo(() => {
        if (!assignees || assignees.length === 0) {
            return null;
        }
        const query = {
            select: 'all',
            filter: {
                technicianIds: assignees.filter((a) => isTechnician(a)).map((a) => a.info.id),
                range: {
                    start: date.startOf('day').toDate(),
                    end: date.endOf('day').toDate()
                }
            }
        };
        return query;
    }, [assignees, date]);
    const { data: timeOffs, loading: timeOffsLoading, error: timeOffsError } = useGetTimeOffs(timeOffsQuery);
    return { timeOffs, timeOffsLoading };
};
export default useGetSchedulerTimeOffs;
