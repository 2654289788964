import React, { useContext } from 'react';
import FilterPanel from '@components/FilterPanel';
import I18n from '@utils/i18n';
import Statuses from './Statuses';
import View from './View';
import CreateEvent from '../CreateEvent';
import { CalendarContext } from '../../CalendarContext';
import useFilters from '../../hooks/useFilters';
import AssigneeMultiSelect from '../../../../components/Multiselects/AssigneeMultiselect/index.tsx';

const kendoCacheKey = 'kendo/calendar';

function Header() {
  const {
    actions: { onResetFilters, setTechnicians, setSubcontractors },
    state: { loading, selectedTechnicianIds, selectedSubcontractorIds, filters: { values: filterValuesState } }
  } = useContext(CalendarContext);

  const onResetCache = () => {
    // eslint-disable-next-line no-restricted-globals, no-alert
    if (!confirm(I18n.t('generic.are_you_sure'))) return;

    localStorage.removeItem(kendoCacheKey);
    window.location.reload();
  };

  const filters = useFilters();

  const style = loading ? { pointerEvents: 'none', top: '0' } : { top: '0' };

  return (
    <>
      <FilterPanel onResetFilters={onResetFilters} onResetCache={onResetCache} filters={filters} />
      <div className="qmb-actions--sticky" style={style}>
        <div className="actions__group--flex">
          <CreateEvent withTitle />
          <AssigneeMultiSelect
            teamIds={filterValuesState.technicianTeam}
            selectedTechnicianIds={selectedTechnicianIds === 'all' ? [] : selectedTechnicianIds} // Handle residual 'all' in caches
            onTechChange={(selections) => {
              setTechnicians(selections);
            }}
            selectedSubcontractorIds={selectedSubcontractorIds === 'all' ? [] : selectedSubcontractorIds}
            onSubChange={(selections) => {
              setSubcontractors(selections);
            }}
          />
          <Statuses />
        </div>
        <div className="actions__group">
          <View />
        </div>
      </div>
    </>
  );
}

export default Header;
