import React, { useContext, useMemo, useRef } from 'react';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { EventContext } from './EventContext';
import { SchedulerContext } from '../../Contexts/SchedulerContext';
import { ScheduleAndDispatchContext } from '../../Contexts/ScheduleAndDispatchContext';
function EventTimeOff() {
    const { event } = useContext(EventContext);
    const { clusterInfo, metadata } = useContext(EventContext);
    const { openTimeOffModal } = useContext(ScheduleAndDispatchContext);
    const dynamicClass = useMemo(() => {
        const classes = ['event__item'];
        if (metadata.overflowPrevDay || metadata.overflowNextDay)
            classes.push('--overflow');
        if (metadata.overflowPrevDay)
            classes.push('--prev');
        if (metadata.overflowNextDay)
            classes.push('--next');
        return classes.join('');
    }, [metadata]);
    const dynamicStyles = useMemo(() => {
        return {
            left: `${metadata.startTimeAsPercentage}%`,
            width: `${metadata.endTimeAsPercentage - metadata.startTimeAsPercentage}%`,
            height: `${(100 / clusterInfo.subdivision) * clusterInfo.span}%`,
            top: `calc((${100 / clusterInfo.subdivision}% ) * ${clusterInfo.offset})`,
            borderTop: clusterInfo.offset === 0 ? 'unset' : 'transparent'
        };
    }, [event, clusterInfo, metadata]);
    return (React.createElement("div", { className: dynamicClass, style: dynamicStyles, onClick: () => openTimeOffModal(event.id) },
        React.createElement("div", { className: "event__card" },
            React.createElement(OverflowIndicators, null),
            React.createElement(Tooltip, { anchorElement: "target", position: "top" },
                React.createElement("div", { className: "event__crux" },
                    React.createElement("span", { className: "event__type" },
                        React.createElement("i", { className: "fa-light fa-calendar-clock" })),
                    React.createElement("span", { className: "event__time" }, event.start.format('hh:mm a')),
                    React.createElement("span", { className: "event__title" }, event.title))))));
}
function OverflowIndicators() {
    const { metadata } = useContext(EventContext);
    const { incrementCurrentDate, decrementCurrentDate } = useContext(SchedulerContext);
    const left = useRef(null);
    const right = useRef(null);
    const ActivateIndicator = (ref) => {
        ref.current.classList.add('--active');
    };
    const DeactivateIndicator = (ref) => {
        ref.current.classList.remove('--active');
    };
    const HandleClick = (e, isLeftIndicator) => {
        e.stopPropagation();
        if (isLeftIndicator)
            decrementCurrentDate();
        else
            incrementCurrentDate();
    };
    const leftIndicator = !metadata.overflowPrevDay ? null : (React.createElement("div", { ref: left, className: "event__nav--left" },
        React.createElement("button", { className: "qmb-control--icon", onMouseEnter: (e) => ActivateIndicator(left), onMouseLeave: (e) => DeactivateIndicator(left), onClick: (e) => HandleClick(e, true) },
            React.createElement("i", { className: "fa-light fa-angle-left" }))));
    const rightIndicator = !metadata.overflowNextDay ? null : (React.createElement("div", { ref: right, className: "event__nav--right" },
        React.createElement("button", { className: "qmb-control--icon", onMouseEnter: (e) => ActivateIndicator(right), onMouseLeave: (e) => DeactivateIndicator(right), onClick: (e) => HandleClick(e, false) },
            React.createElement("i", { className: "fa-light fa-angle-right" }))));
    return (React.createElement(React.Fragment, null,
        leftIndicator,
        rightIndicator));
}
export default EventTimeOff;
