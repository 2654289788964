import React, { useContext, useRef } from 'react';
import { AssigneeMultiSelectContext } from '../AssigneeMultiSelectContext';
function TechSubSelector(params) {
    const { selectedTechnicians, selectedSubcontractors, setActiveMultiSelector, setSelectedTechnicians, setSelectedSubcontractors } = useContext(AssigneeMultiSelectContext);
    const buttonStyle = { justifyContent: 'space-between' };
    const counterStyle = {
        marginRight: '2rem',
        background: '#F0EDF8',
        color: '#3D1B9D',
        boxSizing: 'border-box',
        padding: '.2rem',
        borderRadius: '20%'
    };
    const techAnchor = useRef(null);
    const subAnchor = useRef(null);
    const handleTechClick = () => {
        setActiveMultiSelector("technician" /* AssigneeType.TECHNICIAN */);
    };
    const handleSubClick = () => {
        setActiveMultiSelector("subcontractor" /* AssigneeType.SUBCONTRACTOR */);
    };
    return (React.createElement("div", { className: "qmb-popup--actions--y-max" },
        React.createElement("div", { className: "popup__section--y-fill" },
            React.createElement("button", { className: "qmb-control--menu", type: "button", style: buttonStyle, onClick: handleTechClick },
                React.createElement("span", null, "Our Technicians"),
                React.createElement("span", { style: counterStyle, ref: techAnchor.current }, selectedTechnicians.length > 0 ? selectedTechnicians.length : null)),
            React.createElement("button", { className: "qmb-control--menu", type: "button", style: buttonStyle, onClick: handleSubClick },
                React.createElement("span", null, "Subcontractors"),
                React.createElement("span", { style: counterStyle, ref: subAnchor.current }, selectedSubcontractors.length > 0 ? selectedSubcontractors.length : null))),
        selectedSubcontractors.length + selectedTechnicians.length > 0 && (React.createElement(React.Fragment, null,
            React.createElement("hr", { className: "popup__split" }),
            React.createElement("div", { className: "popup__section" },
                React.createElement("ul", { role: "presentation" },
                    React.createElement("li", null,
                        React.createElement("div", { className: "messageContainer" },
                            React.createElement("button", { type: "button", className: "qmb-control--sm--reset", onClick: params.onReset },
                                React.createElement("i", { className: "fa-light fa-arrow-rotate-left" }),
                                "Reset")))))))));
}
export default TechSubSelector;
