import React, { createContext, useCallback, useContext, useMemo, useRef, useState } from 'react';
import { SchedulerContext } from './SchedulerContext';
export const RearrangeContext = createContext(undefined);
export function RearrangeContextProvider(params) {
    const { moveEvent, reassignEvent, adjustEventTime, activeDate, activeEvent, activeEventOriginalState, currentMouseTime, setActiveSchedulerEvent } = useContext(SchedulerContext);
    const targetAssignee = useRef(null);
    const offsetMinutes = useRef(0);
    const isSnapping = useRef(false);
    const [isMoving, setIsMoving] = useState(false);
    const handleMouseMove = useCallback((mouseEvent) => {
        const visit = activeEvent.current;
        const destinationTime = currentMouseTime.current.subtract(offsetMinutes.current, 'minute');
        if (isSnapping.current && destinationTime.minute() % 5 !== 0) {
            return;
        }
        moveEvent(visit.key, destinationTime);
    }, []);
    const handleTimeAdjustmentMouseMove = useCallback((mouseEvent, timeType) => {
        const visit = activeEvent.current;
        const destinationTime = currentMouseTime.current;
        if (isSnapping.current && destinationTime.minute() % 15 !== 0) {
            return;
        }
        adjustEventTime(visit.key, destinationTime, timeType);
    }, []);
    const startRearrangingVisit = useCallback((selectedVisit, snap) => {
        targetAssignee.current = selectedVisit.assignee;
        isSnapping.current = snap;
        setIsMoving(true);
        const clickTimePlacement = currentMouseTime.current;
        offsetMinutes.current = clickTimePlacement.isBefore(selectedVisit.start)
            ? 0
            : clickTimePlacement.diff(selectedVisit.start, 'minutes');
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', () => {
            document.removeEventListener('mousemove', handleMouseMove);
        }, { once: true });
    }, []);
    const startTimeAdjustment = useCallback((selectedVisit, timeType, snap) => {
        setActiveSchedulerEvent(selectedVisit);
        isSnapping.current = snap;
        const HandleResizing = (e) => handleTimeAdjustmentMouseMove(e, timeType);
        document.addEventListener('mousemove', HandleResizing);
        document.addEventListener('mouseup', () => {
            document.removeEventListener('mousemove', HandleResizing);
        }, { once: true });
    }, []);
    const setTargetAssignee = useCallback((destinationAssignee) => {
        const visit = activeEvent.current;
        if (visit)
            reassignEvent(visit.key, destinationAssignee);
    }, []);
    const stopRearranging = useCallback(() => {
        setIsMoving(false);
        isSnapping.current = false;
    }, []);
    const contextData = useMemo(() => {
        const value = {
            isMoving,
            startRearrangingVisit,
            startTimeAdjustment,
            setTargetAssignee,
            stopRearranging
        };
        return value;
    }, [isMoving]);
    return React.createElement(RearrangeContext.Provider, { value: contextData }, params.children);
}
