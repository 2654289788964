import React, { createContext, useCallback, useMemo, useRef, useState } from 'react';
import GlobalDragItem from '../GlobalDrag/GlobalDragItem';
export const GlobalDragContext = createContext(undefined);
export function GlobalDragContextProvider(params) {
    const activeGlobalDragItem = useRef(undefined);
    const [dragItem, setDragItem] = useState(undefined);
    const [currentMouseX, setCurrentMouseX] = useState(0);
    const [currentMouseY, setCurrentMouseY] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    const cancelEvent = useRef(() => {
        console.log('No cancellation event provided');
    });
    const startDragging = useCallback((item, onCancel) => {
        cancelEvent.current = onCancel;
        activeGlobalDragItem.current = item;
        setDragItem(React.createElement(GlobalDragItem, null));
        setIsDragging(true);
        document.addEventListener('mousemove', HandleMouseMove);
        document.addEventListener('mouseup', HandleCancelEvent, { once: true });
    }, []);
    const stopDragging = useCallback(() => {
        activeGlobalDragItem.current = undefined;
        setDragItem(undefined);
        setIsDragging(false);
        document.removeEventListener('mouseup', HandleCancelEvent);
        document.removeEventListener('mousemove', HandleMouseMove);
    }, []);
    const changeCancelEvent = useCallback((onCancel) => {
        cancelEvent.current = onCancel;
    }, []);
    const HandleCancelEvent = useCallback((e) => {
        cancelEvent.current();
    }, []);
    const HandleMouseMove = useCallback((e) => {
        setCurrentMouseX(e.clientX);
        setCurrentMouseY(e.clientY);
    }, []);
    // TODO: Re entry to scheduler
    const contextData = useMemo(() => {
        const value = {
            dragItem,
            activeGlobalDragItem,
            isDragging,
            currentMouseX,
            currentMouseY,
            startDragging,
            stopDragging,
            changeCancelEvent
        };
        return value;
    }, [dragItem, currentMouseX, currentMouseY, isDragging]);
    return React.createElement(GlobalDragContext.Provider, { value: contextData }, params.children);
}
