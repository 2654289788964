import React from 'react';
import { technicianAvatar } from '@utils/functions/technician.functions';
import { subcontractorIcon } from '@utils/functions/subcontractor.functions';
import { isSubcontractor, isTechnician } from '@utils/typeGuards/assignee.typeguard';
export function assigneeType(assignee) {
    return assignee.subcontractor !== undefined ? "subcontractor" /* AssigneeType.SUBCONTRACTOR */ : "technician" /* AssigneeType.TECHNICIAN */;
}
export function assigneeId(assignee) {
    var _a, _b, _c, _d;
    switch (assigneeType(assignee)) {
        case "subcontractor" /* AssigneeType.SUBCONTRACTOR */:
            return (_b = (_a = assignee.subcontractor) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : null;
        case "technician" /* AssigneeType.TECHNICIAN */:
        default:
            return (_d = (_c = assignee.technician) === null || _c === void 0 ? void 0 : _c.id) !== null && _d !== void 0 ? _d : null;
    }
}
export function assigneeTitle(assignee) {
    var _a, _b;
    return (_b = (_a = assignee.info) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : 'Unassigned';
}
export function assigneeIcon(assignee, size = 32) {
    let icon;
    if (isTechnician(assignee))
        icon = technicianAvatar(assignee.info, size);
    else if (isSubcontractor(assignee))
        icon = subcontractorIcon(assignee.info, size);
    else
        icon = React.createElement("i", { className: "fa-light fa-circle-question" });
    return React.createElement("span", { style: { display: 'flex', alignItems: 'center' } }, icon);
}
