import { useCallback, useState } from 'react';
import api from 'axios';
import { buildVisitPatch } from '@utils/functions/visit.functions';
import useVisitProcessor from '@hooks/processors/use-visit-processor';
import { apiInternalInspectionPrimaryVisitPath, apiInternalInspectionVisitPath, apiInternalServiceVisitPath } from '../../routes';
function useVisitPatch() {
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState();
    const [result, setResult] = useState();
    const { processVisit } = useVisitProcessor();
    const save = useCallback((visit) => {
        const controller = new AbortController();
        setSaving(true);
        let url;
        const patchData = buildVisitPatch(visit);
        switch (visit.type) {
            case "Visit" /* VisitType.SERVICE_VISIT */:
                url = apiInternalServiceVisitPath(visit.id);
                break;
            case "InspectionVisit" /* VisitType.INSPECTION_VISIT */:
                url = apiInternalInspectionVisitPath(visit.id);
                break;
            case "Inspection" /* VisitType.INSPECTION_PRIMARY_VISIT */:
                url = apiInternalInspectionPrimaryVisitPath(visit.id);
                break;
        }
        api
            .patch(url, JSON.parse(JSON.stringify(patchData)), { signal: controller.signal })
            .then((response) => {
            const pathResult = response.data.data;
            setResult(processVisit(pathResult));
        })
            .catch((err) => {
            if (!controller.signal.aborted) {
                setResult(undefined);
                setError(err);
            }
        })
            .finally(() => {
            if (!controller.signal.aborted)
                setSaving(false);
        });
    }, [processVisit]);
    return { save, saving, error, result };
}
export default useVisitPatch;
