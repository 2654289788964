import { inspectionStatusPath, mapPath, eventsMapPath } from 'routes';
import api from '@utils/axios';

export const fetchTechnicians = async (filters) => {
  const response = await fetch(`/map/technicians?team_id=${filters?.values?.technicianTeam}`);
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return await response.json();
};

export const fetchTechnicianLocations = async (filterValues) => {
  const queryParams = new URLSearchParams();

  if (filterValues && filterValues.technicians) {
    filterValues.technicians.forEach((technicianId) => {
      queryParams.append('technicians[]', technicianId);
    });
  }

  const response = await fetch(`/map/fleet_tracking?${queryParams.toString()}`);
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return await response.json();
};

export const updateFleetTrackingPassengers = async (techId, vehicleId) => {
  const params = { technician_id: techId, vehicle_id: vehicleId };

  const response = await fetch('/map/update_fleet_tracking_passengers', {
    method: 'post',
    body: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json'
    }
  });
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return await response.json();
};

export const deleteFleetTrackingPassengers = async (techId) => {
  const params = { technician_id: techId };

  const response = await fetch('/map/delete_fleet_tracking_passengers', {
    method: 'post',
    body: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json'
    }
  });
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return await response.json();
};

export const fetchEvents = async (date) => {
  const response = await fetch(eventsMapPath({ date }));
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return await response.json();
};

export const saveVisit = async (event) => {
  const { data } = await api.put(
    mapPath({ id: event.id, type: event.type, format: 'json', _options: true }),
    eventData(event)
  );

  return data;
};

const eventData = (event) => {
  const techId = () => {
    const num = Number(event.technicianId); // Attempt to convert to number
    return !isNaN(num) && num > 0 ? num : null; // Check if it's a valid, positive number
  };

  if (event.type === 'Inspection')
    return {
      inspection: {
        status: event.status,
        technician_id: techId(),
        scheduled_date: event.startTime,
        projected_duration_mins: event.durationMins,
        assignee_type: 'technician'
      }
    };

  if (event.type === 'InspectionVisit')
    return {
      inspection_visit: {
        technician_id: techId(),
        scheduled_date: event.startTime,
        duration_mins: event.durationMins,
        inspection_attributes: { status: event.status, id: event.inspectionId },
        assignee_type: 'technician'
      }
    };

  if (event.type === 'Visit')
    return {
      work_order_visit: {
        visit_status: event.status,
        technician_id: techId(),
        scheduled_date: event.startTime,
        duration: event.durationMins,
        assignee_type: 'technician'
      }
    };
};

export const fetchVisits = async ({ filterValues }) => {
  const queryParams = new URLSearchParams();
  queryParams.append('startDate', moment(filterValues.visitDate.startDate, 'M/D/YYYY').toISOString());
  queryParams.append('endDate', moment(filterValues.visitDate.endDate, 'M/D/YYYY').toISOString());

  if (filterValues.technicians) {
    filterValues.technicians.forEach((technicianId) => {
      queryParams.append('technicians[]', technicianId);
    });
  }

  if (filterValues.technicianTeam) {
    filterValues.technicianTeam.forEach((teamId) => {
      queryParams.append('technicianTeam[]', teamId);
    });
  }

  if (filterValues.assigneeTechIds) {
    // Conditional handle residual cached values 'all'. Have since removed 'all' as option
    if (!(filterValues.assigneeTechIds === 'all')) {
      filterValues.assigneeTechIds.forEach((technicianId) => {
        if (technicianId === null) {
          queryParams.append('technicianIds[]', 'unassigned');
        } else {
          queryParams.append('technicianIds[]', technicianId);
        }
      });
    }
  }

  if (filterValues.assigneeSubIds) {
    if (!(filterValues.assigneeSubIds === 'all')) {
      filterValues.assigneeSubIds.forEach((subcontractorId) => {
        if (subcontractorId === null) {
          queryParams.append('subcontractorIds[]', 'unassigned');
        } else {
          queryParams.append('subcontractorIds[]', subcontractorId);
        }
      });
    }
  }

  if (filterValues.tags) {
    filterValues.tags.forEach((tag) => {
      queryParams.append('tags[]', tag);
    });
  }

  // if (filterValues.building) {
  //   filterValues.building.forEach((tag) => {
  //     queryParams.append('building[]', tag);
  //   });
  // }
  queryParams.append('building', filterValues.building);
  queryParams.append('territory', filterValues.territory);
  queryParams.append('type', filterValues.type);
  queryParams.append('inspectionStatus', filterValues.inspectionStatus);
  queryParams.append('inspectionType', filterValues.inspectionType);
  queryParams.append('workOrderStatus', filterValues.workOrderStatus);
  queryParams.append('asset', filterValues.asset);
  queryParams.append('zipCode', filterValues.zipCode);

  const response = await fetch(`/map/visits?${queryParams.toString()}`);
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return await response.json();
};

export const fetchVisitStatus = async (status) => {
  const response = await fetch(inspectionStatusPath(status));

  if (!response.ok) {
    throw new Error('Fetching visit status failed.');
  }

  return response.json();
};
