import { useMemo } from 'react';
import useGetHolidays from '@hooks/requests/index/use-get-holidays-hook';
const useGetSchedulerHolidays = (date) => {
    const holidaysQuery = useMemo(() => ({
        select: 'all',
        filter: {
            range: {
                start: date.startOf('day').toDate(),
                end: date.endOf('day').toDate()
            }
        }
    }), [date]);
    const { data: holidays, loading: holidaysLoading, error: holidaysError } = useGetHolidays(holidaysQuery);
    return { holidays, holidaysLoading };
};
export default useGetSchedulerHolidays;
