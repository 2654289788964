import React, { useEffect, useState, useMemo, useContext } from 'react';
import { useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import { SchedulerContext } from '../Contexts/SchedulerContext';
import dayjs from 'dayjs';

const MINUTES_IN_DAY = 1440;

function CurrentTimeIndicator() {
  const today = useMemo(() => dayjs(), []);
  const [right, setRight] = useState('');

  const { activeDate } = useContext(SchedulerContext);

  const timeAsPercentage = () => {
    const now = DateTime.now();
    return now.diff(now.startOf('day')).as('minutes') / MINUTES_IN_DAY;
  };

  useEffect(() => {
    const headerDimensions = document.getElementById('scheduler-time-header').getBoundingClientRect();
    setRight((1 - timeAsPercentage()) * headerDimensions.width);
  }, [activeDate]);

  useEffect(() => {
    // On init, default the scheduler scroll to the current time
    const headerDimensions = document.getElementById('scheduler-time-header').getBoundingClientRect();
    document.getElementById('scheduler').scrollLeft = timeAsPercentage() * headerDimensions.width - 200;
  }, []);

  if (!today.isSame(activeDate, 'day')) return null;

  return (
    <div className="schedule__current-time" style={{ right: `${right}px` }}>
      <i className="schedule__current-point" />
    </div>
  );
}

export default CurrentTimeIndicator;
