import { useCallback, useContext } from 'react';
import { dateClient } from '@utils/date-client';
import { TimeZoneContext } from '../../contexts/timezone-context';
const useVisitProcessor = () => {
    const { timezone } = useContext(TimeZoneContext);
    const processVisit = useCallback((visit) => {
        const processedVisit = Object.assign(Object.assign({}, visit), { start: dateClient(visit.start).tz(timezone), end: dateClient(visit.end).tz(timezone) });
        return processedVisit;
    }, [timezone]);
    return { processVisit };
};
export default useVisitProcessor;
