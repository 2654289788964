import React, { useContext, useEffect } from 'react';
import { Window } from '@progress/kendo-react-dialogs';
import { TimeZoneContext } from '../../contexts/timezone-context';
import { TenantContext } from '../../contexts/tenant-context';
function DevSettingsModal(params) {
    const { tenant } = useContext(TenantContext);
    const { timezone, method, setMethod, setTimeZone } = useContext(TimeZoneContext);
    const timezones = [
        'America/Los_Angeles',
        'America/Denver',
        'America/Chicago',
        'America/New_York',
        'Europe/London',
        'Europe/Kyiv',
        'Asia/Qatar',
        'America/Argentina/Buenos_Aires'
    ];
    useEffect(() => {
        document.querySelector('.k-overlay').addEventListener('click', params.onClose);
    }, []);
    return (React.createElement(Window, { modal: true, onClose: params.onClose, minWidth: 240, initialWidth: 480, initialHeight: 300, minHeight: 300, minimizeButton: () => null, maximizeButton: () => null, restoreButton: () => null, className: "qmb-dialog" },
        React.createElement("label", null,
            React.createElement("input", { type: "checkbox", checked: method === 'fixed', onChange: (e) => {
                    setMethod(e.target.checked ? 'fixed' : 'dynamic');
                } }),
            "Fixed Timezone"),
        method === 'dynamic' && React.createElement("span", null,
            "Browser Timezone: ",
            timezone),
        method === 'fixed' && (React.createElement(React.Fragment, null,
            React.createElement("label", null,
                React.createElement("input", { type: "checkbox", checked: timezone === (tenant === null || tenant === void 0 ? void 0 : tenant.timezone), onChange: (e) => {
                        if (e.target.checked)
                            setTimeZone(tenant === null || tenant === void 0 ? void 0 : tenant.timezone);
                        else
                            setTimeZone(Intl.DateTimeFormat().resolvedOptions().timeZone);
                    } }),
                "Use Tenant Timezone"),
            React.createElement("select", { id: "timezone-dropdown", value: timezone, onChange: (e) => {
                    setTimeZone(e.target.value);
                } }, timezones.sort().map((tz) => (React.createElement("option", { key: tz, value: tz }, tz))))))));
}
export default DevSettingsModal;
