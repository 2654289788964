import produce from 'immer';

export const actionTypes = {
  ADD_TAG: 'ADD_TAG',
  REMOVE_TAG: 'REMOVE_TAG'
};

export const reducer = produce((draft, action) => {
  switch (action.type) {
    case actionTypes.REMOVE_TAG:
      draft.tags = draft.tags.filter((x) => x !== action.value);
      break;
    case actionTypes.ADD_TAG:
      if (draft.tags.some((x) => x === action.value)) break;

      draft.tags = [...draft.tags, action.value];
      break;
    default:
      throw new Error('Reducer - Wrong action type.');
  }
});
