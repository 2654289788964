import React, { useContext } from 'react';
import { isVisit } from '@utils/typeGuards/scheduler-event-typeguard';
import { EventContext } from './EventContext';
import EventVisit from './EventVisit';
import EventTimeOff from './EventTimeOff';
function Event() {
    const { event } = useContext(EventContext);
    if (isVisit(event)) {
        return React.createElement(EventVisit, null);
    }
    return React.createElement(EventTimeOff, null);
}
export default Event;
