import { useCallback, useContext } from 'react';
import { dateClient } from '@utils/date-client';
import { TimeZoneContext } from '../../contexts/timezone-context';
const useTimeOffProcessor = () => {
    const { timezone } = useContext(TimeZoneContext);
    const processTimeOff = useCallback((timeOff) => {
        const processedTimeOff = Object.assign(Object.assign({}, timeOff), { start: dateClient(timeOff.start).tz(timezone), end: dateClient(timeOff.end).tz(timezone) });
        return processedTimeOff;
    }, [timezone]);
    return { processTimeOff };
};
export default useTimeOffProcessor;
