import React, { useState, useRef, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Popup } from '@progress/kendo-react-popup';
import { translate } from '@utils/i18n';

const namespace = 'features.settings.tax_items.index';

function ActionButtons({ taxItem, onSwitch, onDuplicate, buttonDuplicated = true, qbEnabled = false }) {
  const [open, setOpen] = useState(false);
  const anchor = useRef(null);
  const popup = useRef(null);

  const handleBodyClick = useCallback((event) => {
    if (
      popup.current &&
      popup.current?._popup?.contains(event.target) &&
      anchor.current &&
      anchor.current.contains(event.target)
    ) {
      setOpen(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('click', handleBodyClick);
    return () => {
      document.removeEventListener('click', handleBodyClick);
    };
  }, [handleBodyClick]);

  const onAddEventPopup = () => {
    setOpen((prev) => !prev);
  };

  return (
    <>
      <span>
        {taxItem.active && (
          <button type="button" disabled={qbEnabled} className="qmb-control--icon" onClick={onSwitch}>
            <i className="fa-light fa-eye" />
          </button>
        )}
        {!taxItem.active && (
          <button type="button" disabled={qbEnabled} className="qmb-control--icon" onClick={onSwitch}>
            <i className="fa-light fa-eye-slash" />
          </button>
        )}
        <button type="button" className="qmb-control--icon" ref={anchor} onClick={onAddEventPopup}>
          <i className="fa-regular fa-ellipsis" />
        </button>
      </span>
      <Popup anchor={anchor.current} show={open} ref={popup}>
        <div className="qmb-popup--action-list">
          <ul role="presentation">
            <li key="duplicate">
              <button type="button" disabled={buttonDuplicated || qbEnabled} onClick={onDuplicate}>
                <i className="fa-light fa-copy" />
                {translate('duplicate', { namespace })}
              </button>
            </li>
          </ul>
        </div>
      </Popup>
    </>
  );
}

ActionButtons.propTypes = {
  taxItem: PropTypes.object.isRequired,
  onSwitch: PropTypes.func.isRequired,
  buttonDuplicated: PropTypes.bool.isRequired,
  onDuplicate: PropTypes.func,
  qbEnabled: PropTypes.bool.isRequired
};

ActionButtons.defaultProps = {
  onDuplicate: null
}

export default ActionButtons;
