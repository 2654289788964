import React, { createElement } from 'react';
import PropTypes from 'prop-types';
import clsx from 'classnames';
import './Table.scss';

export const TableSize = {
  Auto: 'auto',
  Full: 'full'
};

const getCellTemplate = ({ column, row, rowIndex }) => {
  const key = `table-row-${column.dataKey}-${rowIndex}`;
  if (column.renderer) {
    return createElement(column.renderer, { item: row, dataKey: column.dataKey, index: rowIndex, key }, null);
  }
  return row[column.dataKey];
};

export default function Table({
  data,
  isGrouped,
  columns,
  variant = 'detail',
  size = TableSize.Auto,
  expandableRowRenderer,
  onRowClick,
  expandedRows,
  hover
}) {
  const dataGroups = !isGrouped ? [{ data }] : data;
  return (
    <div
      className={clsx('', {
        'qmb-table--01': variant === 'listing',
        'qmb-table--02': variant === 'detail'
      })}
      style={size === TableSize.Full ? { width: '100%' } : {}}>
      <table className="main-table" style={size === TableSize.Full ? { width: '100%' } : {}}>
        <thead>
          <tr>
            {columns.map((column) => (
              <th key={column.dataKey} width={column.width}>
                {column.label}
              </th>
            ))}
          </tr>
        </thead>
        {dataGroups.map((dataGroup, groupIdx) => (
          <tbody key={`table-group-${groupIdx}`}>
            {dataGroup.title && (
              <tr>
                <td className="groupTitle" colSpan={columns.length}>
                  {dataGroup.title}
                </td>
              </tr>
            )}
            {dataGroup.data.map((row, rowIdx) => (
              <React.Fragment key={`table-row-${groupIdx}:${rowIdx}`}>
                <tr
                  key={`table-row-${groupIdx}:${rowIdx}`}
                  onClick={(event) => onRowClick && onRowClick({ item: row }, event)}
                  className={clsx({
                    'expandable-row': expandableRowRenderer,
                    'hoverable': hover
                  })}>

                  {columns.map((column) => (
                    <td
                      key={`table-row-${column.dataKey}-${rowIdx}`}
                      className={clsx({
                        cellAlignRight: column.align === 'right'
                      })}>
                      {getCellTemplate({ column, row, rowIndex: rowIdx })}
                    </td>
                  ))}
                </tr>
                {expandedRows && expandedRows[row.id] && expandableRowRenderer && (
                  <>{expandableRowRenderer({ item: row })}</>
                )}
              </React.Fragment>
            ))}
          </tbody>
        ))}
      </table>
    </div>
  );
}

Table.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  isGrouped: PropTypes.bool,
  variant: PropTypes.oneOf(['listing', 'detail']),
  size: PropTypes.oneOf([TableSize.Auto, TableSize.Full]),
  expandableRowRenderer: PropTypes.func,
  onRowClick: PropTypes.func,
  expandedRows: PropTypes.object,
  hover: PropTypes.bool
};
Table.defaultProps = {
  variant: 'detail',
  isGrouped: false,
  size: TableSize.Auto,
  expandableRowRenderer: null,
  onRowClick: null,
  expandedRows: {},
  hover: false
};
