import { createSlice } from '@reduxjs/toolkit';
import { THIS_MONTH, CUSTOM_RANGE, DATE_FORMAT } from '@constants/Kendo/rangeOptions';

import {
  actionTypes as dateRangePickerActionTypes,
  buildInitialState as buildInitialDateRangeState,
  reducerValuesFunction as dateRangePickerReducerFunction
} from '@components/Kendo/DateRangePickerReducer';
import { actions } from './schedulerSlice';
import { loadCacheFromLocalStorage } from '../../../helpers/localStorage';

export const status = {
  READY: 'READY',
  LOADING: 'LOADING',
  ERROR: 'ERROR'
};

const initialFilterState = {
  values: {
    ...buildInitialDateRangeState('visitDate', THIS_MONTH, true),
    assigneeTechIds: [],
    assigneeSubIds: [],
    technicians: [-1],
    building: '',
    technicianTeam: '',
    territory: '',
    tags: [],
    type: '',
    inspectionStatus: '',
    inspectionType: '',
    workOrderStatus: '',
    asset: '',
    zipCode: ''
  },
  active: {
    visitDate: true,
    assignee: true,
    technicians: true,
    building: false,
    technicianTeam: false,
    territory: false,
    tags: false,
    type: false,
    inspectionStatus: false,
    inspectionType: false,
    workOrderStatus: false,
    asset: false,
    zipCode: false
  }
};

export const buildInitialState = () => {
  const savedState = loadCacheFromLocalStorage('kendo/map_scheduler');
  const filters = cacheIsValid(savedState) ? savedState.stateFilters.filters : initialFilterState;
  return {
    filters,
    status: status.READY,
    visits: [],
    saveQueue: [],
    mapActive: savedState?.stateFilters?.mapActive !== false,
    visitDateFilterMessage: null
  };
};

const cacheIsValid = (cache) => {
  if (!cache) return false;

  if (
    cache.stateFilters &&
    cache.stateFilters.filters &&
    cache.stateFilters.filters.values &&
    cache.stateFilters.filters.active
  )
    return true;

  localStorage.removeItem('kendo/map_scheduler');
  return false;
};

export const visitsSlice = createSlice({
  name: 'map',
  initialState: buildInitialState(),
  reducers: {
    toggleMap: (state, action) => {
      state.mapActive = !state.mapActive;
    },
    shiftSaveQueue: (state, action) => {
      state.saveQueue.shift();
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setFilterValue: (state, action) => {
      const { field, value } = action.payload;

      if (field === 'technicianTeam') {
        state.filters.values.assigneeTechIds = [];
        state.filters.values.assigneeSubIds = [];
      }

      state.filters.values[field] = value;
    },
    toggleFilter: (state, action) => {
      const { field } = action.payload;

      state.filters.active[field] = !state.filters.active[field];

      if (field === 'assignee') {
        state.filters.values.assigneeTechIds = [];
        state.filters.values.assigneeSubIds = [];
      } else {
        state.filters.values[field] = '';
      }
    },
    resetFilters: (state, action) => {
      state.filters = initialFilterState;
    },
    setVisitDateFilter: (state, action) => {
      const tmp = { filters: JSON.parse(JSON.stringify(state.filters)) }; // Need to refactor entire filters. This not great
      dateRangePickerReducerFunction(tmp, action.payload);
      state.filters.values.visitDate = tmp.filters.values.visitDate;
      state.visitDateFilterMessage = null;
    },
    setVisitDateFilterMessage: (state, action) => {
      state.visitDateFilterMessage = action.payload;
    },
    setVisits: (state, action) => {
      state.visits = action.payload;
    },
    assignVisitToTechnician: (state, action) => {
      const { visit, technician } = action.payload;
      const selectedVisit = state.visits.find((x) => x.id === visit.id && x.type === visit.type);
      selectedVisit.technician = technician;
      selectedVisit.technicianId = technician.id;
      state.saveQueue.push(selectedVisit);
    },
    reloadVisit: (state, action) => {
      const targetVisit = state.visits.find((x) => x.id === action.payload.id && x.type === action.payload.type);
      targetVisit.startTime = action.payload.startTime;
      targetVisit.endTime = action.payload.endTime;
    },
    replaceVisit: (state, action) => {

      const matchesActiveFilters = () => {
        let isValid = true;

        // Out of Date Range Check
        if (state.filters.active.visitDate){
          const filterStart = moment(state.filters.values.visitDate.startDate).startOf('day')
          const filterEnd = moment(state.filters.values.visitDate.endDate).startOf('day')
          const visitStart = moment(action.payload.startTime).startOf('day')
          const visitEnd = moment(action.payload.endTime).startOf('day')

          const isBefore = visitEnd.isBefore(filterStart);
          const isAfter = visitStart.isAfter(filterEnd);

          if (isBefore || isAfter)
            isValid = false;
        }


        if (state.filters.active.assignee) {

          if (!(state.filters.values.assigneeTechIds.length === 0 && state.filters.values.assigneeSubIds.length === 0)){
            if (action.payload.assignee.subcontractor !== undefined) {
              if (
                !state.filters.values.assigneeSubIds.some((x) => x === (action.payload.assignee.subcontractor?.id ?? null))
              ) {
                isValid = false;
              }

            }
            else if (!state.filters.values.assigneeTechIds.some((x) => x === action.payload.technicianId)) {
              isValid = false;
            }
          }
        };


        // Invalid Status & Type Check
        if (state.filters.active.type) {
          if (state.filters.values.type === 'Inspection'){
            if (state.filters.values.inspectionStatus !== '' && state.filters.values.inspectionStatus !== action.payload.status)
              isValid = false

            if (state.filters.values.inspectionType !== '' && state.filters.values.inspectionType !== action.payload.type)
              isValid = false
          }
          else if (state.filters.values.type === 'WorkOrder'){
            if (state.filters.values.workOrderStatus !== '' && state.filters.values.workOrderStatus !== action.payload.status)
              isValid = false
          }
        }


        // Hidden Status Check
        const cancelledStatuses = [
          'deleted_by_technician',
          'cancelled',
          'Cancelled',
          'cancelled_by_parent_tenant',
          'DeletedByTechnician'
        ];
        if (cancelledStatuses.findIndex((x) => x === action.payload.status) >= 0) isValid = false;


        return isValid;
      }

      const matchesFilters = matchesActiveFilters()
      const index = state.visits.findIndex((x) => x.key === action.payload.key);
      const allEvents = state.visits.map((v) => v);
      if (index !== -1) {
        // Found in visit list
        if (matchesFilters) {
          allEvents[index] = action.payload;
          state.visits = allEvents;
        } else {
          allEvents.splice(index, 1);
          state.visits = allEvents;
        }
      } else if (matchesFilters) {
        allEvents.push(action.payload);
        state.visits = allEvents;
      }
    }
  }
});

export const {
  setFilterValue,
  toggleFilter,
  setStatus,
  setVisitDateFilter,
  setVisits,
  assignVisitToTechnician,
  shiftSaveQueue,
  reloadVisit,
  resetFilters,
  replaceVisit,
  toggleMap,
  setVisitDateFilterMessage
} = visitsSlice.actions;

export default visitsSlice.reducer;
