import React, { useState } from 'react';
import PropTypes from 'prop-types';
import api from '@utils/axios';
import I18n, { translate } from '@utils/i18n';
import { photoPath, buildingPath } from 'routes';
import { actionTypes } from '../reducer';
import InputTextArea from '../../../components/InputTextArea/InputTextArea';

const namespace = 'features.photo_gallery.photo_detail';

function PhotoDetail({ photo, yearMonth, dispatch }) {
  const [description, setDescription] = useState(photo.description);
  const [internal, setInternal] = useState(photo.internal);
  const [loading, setLoading] = useState(false);

  const onSave = async (newDescription) => {
    setLoading(true);
    await api
      .put(photoPath({ id: photo.id, format: 'json', type: photo.type, _options: true }), {
        photo: { caption: newDescription, is_internal: internal }
      })
      .then(({ data }) => dispatch({ type: actionTypes.PHOTO_UPDATED, newPhoto: data, yearMonth }))
      .then(() => {
        setDescription(newDescription);
        document.querySelector('div.yarl__container.yarl__flex_center').focus();
      });
    setLoading(false);
  };

  const onCancelClick = () => {
    setInternal(photo.internal);
    document.querySelector('div.yarl__container.yarl__flex_center').focus();
  };

  return (
    <>
      <div className="media__major">
        <img className="image_detail" src={photo.large} alt={photo.description} title={photo.description} />
      </div>
      <div className="media__aside">
        <div className="media__section--details">
          <InputTextArea
            onCancel={onCancelClick}
            editLeftControl={
              <div className="qmb-checkbox" style={{ marginRight: 'auto' }}>
                <input
                  className="qmb-checkbox__input"
                  type="checkbox"
                  id="internal_only"
                  checked={internal}
                  onChange={() => setInternal(!internal)}
                />
                <label htmlFor="internal_only">{translate('internal_only', { namespace })}</label>
              </div>
            }
            leftControl={
              photo.internal && (
                <div className="qmb-checkbox" style={{ marginRight: 'auto' }}>
                  <label htmlFor="internal_only">{translate('internal_only', { namespace })}</label>
                </div>
              )
            }
            rightControl={
              <em>
                {translate('updated_on', { namespace })} {new Date(photo.updatedAt).toLocaleDateString('en-US')}
              </em>
            }
            loading={loading}
            id="photo_description"
            editable
            value={description ?? ''}
            onSave={onSave}
            onChange={setDescription}
            label="Description"
          />
        </div>

        <hr className="media__split" />

        <div className="media__section">
          <div className="content__item">
            <span className="content__label">{translate('photo_type', { namespace })}</span>
            <span className="content__value">{photo.photoType}</span>
          </div>
          <div className="content__item">
            <span className="content__label">{translate('date_taken', { namespace })}</span>
            <span className="content__value">{new Date(photo.dateTaken).toLocaleDateString('en-US')}</span>
          </div>
          {photo.building && (
            <div className="content__item">
              <span className="content__label">{I18n.t('generic.building')}</span>
              <span className="content__value">
                <a href={buildingPath(photo.building.id)} target="_blank" rel="noreferrer">
                  {photo.building.name}
                </a>
              </span>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

PhotoDetail.propTypes = {
  dispatch: PropTypes.func.isRequired,
  yearMonth: PropTypes.string.isRequired,
  photo: PropTypes.object.isRequired
};

export default PhotoDetail;
