import React, { useEffect, useMemo, createContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import api from '@utils/axios';
import { calendarPath, eventsCalendarPath } from 'routes';
import { saveCacheToLocalStorage } from '../../helpers/localStorage';
import { actionTypes, reducer } from './reducer';
import { getInitialData, INITIAL_FILTER_STATE } from './helpers/initialData';

const CACHE_KEY = 'kendo/calendar';

export const CalendarContext = createContext({
  state: {},
  actions: {}
});

export function CalendarProvider({
  assetsForSelect,
  territoriesForSelect,
  inspectionTypesForSelect,
  inspectionStatusesForSelect,
  visitStatusesForSelect,
  techniciansForSelect,
  permissions,
  timeZone,
  children
}) {
  const [state, dispatch] = useReducer(
    reducer,
    { technicians: techniciansForSelect, cacheKey: CACHE_KEY },
    getInitialData
  );

  const { range, unselectedTechs, statuses, filters, detailed, selectedTechnicianIds, selectedSubcontractorIds } =
    state;

  const visitStatusesForHighlight = useMemo(
    () => visitStatusesForSelect.filter((status) => !['deleted_by_technician', 'cancelled'].includes(status.value)),
    []
  );

  useEffect(() => {
    if (!range?.startStr) return;

    fetchEvents({ range, filters, selectedTechnicianIds, selectedSubcontractorIds }).then(({ data }) =>
      dispatch({ type: actionTypes.DATA_LOADED, data })
    );
  }, [range, filters, selectedTechnicianIds, selectedSubcontractorIds]);

  useEffect(() => {
    saveCacheToLocalStorage(CACHE_KEY, {
      filters,
      detailed,
      statuses,
      unselectedTechs,
      selectedTechnicianIds,
      selectedSubcontractorIds,
      view: range?.view?.type
    });
  }, [
    filters,
    detailed,
    statuses,
    unselectedTechs,
    selectedTechnicianIds,
    selectedSubcontractorIds,
    range?.view?.type
  ]);

  const onEventSave = (formData) => {
    dispatch({ type: actionTypes.LOADING_CHANGED, loading: true });
    updateEvent(formData).then(() => {
      fetchEvents({ range, filters }).then(({ data }) => dispatch({ type: actionTypes.DATA_LOADED, data }));
    });
  };

  const onDateRangeChange = (event) => {
    dispatch({ type: actionTypes.DATE_CHANGED, range: event });
  };

  const onEventChange = (data) => {
    const newEvent = {
      ...data.event.extendedProps,
      id: data.event.id,
      title: data.event.title,
      start: data.event.start,
      end: data.event.end
    };

    updateEvent(newEvent)
      .then(() => {
        dispatch({ type: actionTypes.LOADING_CHANGED, loading: true });
        fetchEvents({ range, filters }).then(({ data: newData }) =>
          dispatch({ type: actionTypes.DATA_LOADED, data: newData })
        );
      })
      .catch(({ response }) => {
        data.revert();
        dispatch({ type: actionTypes.NETWORK_REQUEST_FAILED, errorMessage: response.data.error });
      });
  };

  const reloadEvents = () => {
    dispatch({ type: actionTypes.LOADING_CHANGED, loading: true });
    fetchEvents({ range, filters }).then(({ data }) => dispatch({ type: actionTypes.DATA_LOADED, data }));
  };

  const onToggleStatus = (status) => {
    return () => {
      dispatch({ type: actionTypes.STATUS_TOGGLED, field: status });
    };
  };

  const toggleSelectAllStatuses = () => {
    dispatch({ type: actionTypes.SELECT_ALL_STATUSES, visitStatusesForHighlight, inspectionStatusesForSelect });
  };

  const toggleUnselectAllStatuses = () => {
    dispatch({ type: actionTypes.UNSELECT_ALL_STATUSES });
  };

  const toggleSelectAllTechs = () => {
    dispatch({ type: actionTypes.SELECT_ALL_TECHS });
  };

  const toggleUnselectAllTechs = () => {
    dispatch({ type: actionTypes.UNSELECT_ALL_TECHS });
  };

  const setTechnicians = (ids) => {
    dispatch({ type: actionTypes.SET_TECHNICIANS, ids });
  };

  const setSubcontractors = (ids) => {
    dispatch({ type: actionTypes.SET_SUBCONTRACTORS, ids });
  };

  const onToggleTech = (id) => {
    return (e) => {
      dispatch({ type: actionTypes.TECH_TOGGLED, checked: e.target.checked, id });
    };
  };

  const onChangeView = () => {
    dispatch({ type: actionTypes.DETAILED_TOGGLED });
  };

  const onResetFilters = () => {
    dispatch({
      type: actionTypes.FILTER_CHANGED,
      filters: { active: filters.active, values: INITIAL_FILTER_STATE.values }
    });
  };

  const fetchEvents = () => {
    const queryParams = new URLSearchParams();
    if (filters?.values?.building) {
      filters?.values?.building.forEach((buildingId) => {
        queryParams.append('filters[building][]', buildingId);
      });
    }
    if (filters?.values?.asset && Array.isArray(filters?.values?.asset)) {
      queryParams.append('assets', filters?.values?.asset.join(','));
    }
    if (filters?.values?.territory) {
      filters?.values?.territory.forEach((territoryId) => {
        queryParams.append('filters[territory][]', territoryId);
      });
    }

    if (filters?.values?.technicianTeam) {
      if (Array.isArray(filters?.values?.technicianTeam)) {
        filters?.values?.technicianTeam.forEach((teamId) => {
          queryParams.append('filters[technicianTeam][]', teamId);
        });
      } else {
        queryParams.append('filters[technicianTeam][]', filters?.values?.technicianTeam);
      }
    }

    const { building, territory, technicianTeam, asset, ...filterValues } = filters.values;

    return api.get(`${eventsCalendarPath({ format: 'json' })}?${queryParams.toString()}`, {
      params: {
        start: range.startStr,
        end: range.endStr,
        filters: filterValues,
        technicianIds:
          selectedTechnicianIds === 'all' ? [] : selectedTechnicianIds.map((id) => (id === null ? 'unassigned' : id)),
        subcontractorIds:
          selectedSubcontractorIds === 'all'
            ? []
            : selectedSubcontractorIds.map((id) => (id === null ? 'unassigned' : id))
      }
    });
  };

  const updateEvent = (event) => {
    return api.put(calendarPath({ id: event.objectId, type: event.type, _options: true }), eventData(event));
  };

  const onChangeSearchFilter = (fieldName) => {
    return (e) => {
      dispatch({ type: actionTypes.FILTER_CHANGED, value: e.sender.value(), field: fieldName });
    };
  };

  const handleMultiSelectFilterChange = (fieldName) => {
    return (value) => {
      dispatch({ type: actionTypes.FILTER_CHANGED, value, field: fieldName });
    };
  };

  const onChangeReactFilter = (fieldName) => {
    return (value) => {
      dispatch({ type: actionTypes.FILTER_CHANGED, value, field: fieldName });
    };
  };

  const onToggleFilter = (fieldName) => {
    return () => {
      dispatch({ type: actionTypes.FILTER_TOGGLED, field: fieldName });
    };
  };

  const onTypeFilterChange = () => {
    return (value) => {
      dispatch({ type: actionTypes.FILTER_CHANGED, value, field: 'type' });
      dispatch({ type: actionTypes.FILTER_CHANGED, value: '', field: 'inspectionStatus' });
      dispatch({ type: actionTypes.FILTER_CHANGED, value: '', field: 'inspectionType' });
      dispatch({ type: actionTypes.FILTER_CHANGED, value: '', field: 'visitStatus' });
    };
  };

  const onTypeFilterToggle = () => {
    return () => {
      dispatch({ type: actionTypes.FILTER_TOGGLED, field: 'type' });
      dispatch({ type: actionTypes.FILTER_CHANGED, value: '', field: 'inspectionStatus' });
      dispatch({ type: actionTypes.FILTER_CHANGED, value: '', field: 'inspectionType' });
      dispatch({ type: actionTypes.FILTER_CHANGED, value: '', field: 'visitStatus' });
    };
  };

  const eventData = (event) => {
    if (event.type === 'Inspection')
      return {
        inspection: {
          status_code: event.status,
          technician_id: event.technicianId,
          scheduled_date: event.start,
          projected_duration_mins: event.durationMins
        }
      };

    if (event.type === 'InspectionVisit')
      return {
        inspection_visit: {
          technician_id: event.technicianId,
          scheduled_date: event.start,
          duration_mins: event.durationMins,
          inspection_attributes: { status_code: event.status, id: event.inspectionId }
        }
      };

    if (event.type === 'Visit')
      return {
        work_order_visit: {
          visit_status: event.status,
          technician_id: event.technicianId,
          scheduled_date: event.start,
          duration: event.durationMins
        }
      };
  };

  const contextValue = useMemo(() => {
    return {
      assetsForSelect,
      territoriesForSelect,
      inspectionTypesForSelect,
      inspectionStatusesForSelect,
      visitStatusesForSelect,
      visitStatusesForHighlight,
      techniciansForSelect,
      permissions,
      timeZone,
      state,
      actions: {
        onEventSave,
        onEventChange,
        onDateRangeChange,
        onToggleStatus,
        toggleSelectAllStatuses,
        toggleUnselectAllStatuses,
        toggleSelectAllTechs,
        toggleUnselectAllTechs,
        onToggleTech,
        setTechnicians,
        setSubcontractors,
        onChangeView,
        onResetFilters,
        onChangeSearchFilter,
        handleMultiSelectFilterChange,
        onChangeReactFilter,
        onToggleFilter,
        onTypeFilterChange,
        onTypeFilterToggle,
        reloadEvents
      }
    };
  }, [state, dispatch]);

  return <CalendarContext.Provider value={contextValue}>{children}</CalendarContext.Provider>;
}

CalendarProvider.propTypes = {
  assetsForSelect: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any
    })
  ),
  territoriesForSelect: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any
    })
  ),
  inspectionTypesForSelect: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any
    })
  ),
  inspectionStatusesForSelect: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any
    })
  ),
  visitStatusesForSelect: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any
    })
  ),
  techniciansForSelect: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any
    })
  ),
  permissions: PropTypes.shape({
    settings: PropTypes.bool,
    techs: PropTypes.bool,
    statuses: PropTypes.bool,
    hasService: PropTypes.bool
  }).isRequired,
  timeZone: PropTypes.string.isRequired,
  children: PropTypes.object.isRequired
};

CalendarProvider.defaultProps = {
  assetsForSelect: [],
  territoriesForSelect: [],
  inspectionTypesForSelect: [],
  inspectionStatusesForSelect: [],
  visitStatusesForSelect: [],
  techniciansForSelect: []
};
